import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { gql, ApolloError } from 'apollo-boost'
import {
  RiskScoreQuery,
  RiskScoreQueryVariables,
  Severity,
} from 'spartacus/types/__generated_graph'
import Constants from 'spartacus/constants'
import { getSeverityByRiskScore } from 'spartacus/utils'

const RISK_SCORE_QUERY = gql`
  query RiskScore($email: String) {
    riskScore(email: $email)
  }
`

export const SEVERITY_QUERY = gql`
  query Severity {
    severity @client
  }
`

interface Data extends RiskScoreQuery {
  severity: Severity
}

const useRiskScore = (
  variables: RiskScoreQueryVariables,
): {
  loading: boolean
  error: ApolloError | undefined
  data: Data | undefined
} => {
  const client = useApolloClient()
  const { loading, error, data, startPolling, stopPolling } = useQuery<
    RiskScoreQuery,
    RiskScoreQueryVariables
  >(RISK_SCORE_QUERY, { ssr: false, variables })

  // TODO: this is temporary while we figure out Analytics MobX
  client.writeData({ data: { severity: getSeverityByRiskScore(data?.riskScore) } })

  if (Constants.IS_BROWSER && !loading && !error && typeof data?.riskScore !== 'number') {
    startPolling(2000)

    return {
      loading: true,
      error,
      data: undefined,
    }
  }

  if (error || typeof data?.riskScore === 'number') {
    stopPolling()
  }

  return {
    loading,
    error,
    data: data
      ? {
          ...data,
          severity: getSeverityByRiskScore(data.riskScore),
        }
      : undefined,
  }
}

export default useRiskScore
