import { action, observable, runInAction, reaction } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, { RequestState } from 'spartacus/services/TransportLayer'
import SessionStore from 'spartacus/stores/SessionStore'

export default class ExposedPasswordStore {
  private static RETRY_FREQUENCY = 2000

  @observable public requestState: RequestState = 'not started'
  public readonly exposedPasswords = observable<string>([])

  private transportLayer: TransportLayer
  private sessionStore: SessionStore

  public constructor(transportLayer: TransportLayer, sessionStore: SessionStore) {
    this.transportLayer = transportLayer
    this.sessionStore = sessionStore

    // Listen to email and publicEmail changes and reset
    reaction(() => `${this.sessionStore.user?.email}${this.sessionStore.publicEmail}`, this.reset)
  }

  public getExposedPasswords = async (): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })

      const exposedPasswords = await this.pollForExposedPasswords()

      runInAction((): void => {
        this.requestState = 'success'
        this.exposedPasswords.replace(exposedPasswords)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  private pollForExposedPasswords = async (): Promise<string[]> => {
    try {
      const email = this.sessionStore.user ? undefined : this.sessionStore.publicEmail
      const response = await this.transportLayer.getExposedPasswords(email)

      if (Object.prototype.hasOwnProperty.call(response, 'status')) {
        await this.waitForRetry()
        return this.pollForExposedPasswords()
      }

      return response as string[]
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, ExposedPasswordStore.RETRY_FREQUENCY))

  @action private reset = (): void => {
    this.requestState = 'not started'
    this.exposedPasswords.replace([])
  }
}
