
    var doc = {"kind":"Document","definitions":[{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"Severity"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"Unknown"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"Low"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"Medium"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"High"},"directives":[]}]},{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"PrivacySeverity"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"Unknown"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"NeedsWork"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"Decent"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"Great"},"directives":[]}]},{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Query"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"severity"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Severity"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"privacySeverity"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PrivacySeverity"}}},"directives":[]}]}],"loc":{"start":0,"end":196}};
    doc.loc.source = {"body":"enum Severity {\n  Unknown\n  Low\n  Medium\n  High\n}\n\nenum PrivacySeverity {\n  Unknown\n  NeedsWork\n  Decent\n  Great\n}\n\nextend type Query {\n  severity: Severity!\n  privacySeverity: PrivacySeverity!\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
