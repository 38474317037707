import { action, reaction, observable, runInAction, computed } from 'mobx'
import * as Sentry from '@sentry/browser'
import SessionStore from 'spartacus/stores/SessionStore'
import TransportLayer, { RequestState } from 'spartacus/services/TransportLayer'

export enum RawExposureStatus {
  // Starting statuses:
  scanning = 'scanning',
  unscanned = 'unscanned', // record has been added without being scanned (e.g. for brokers without search)
  scanned = 'scanned', // record has been scanned, default state
  // In progress statuses:
  removing = 'removing', // removal (opt out) has been started
  removed = 'removed', // removal (opt out) has been finished
  failed = 'failed', // removal (opt out) failed
  // Final statuses:
  solved = 'solved', // removal (opt out) has been verified
  unremovable = 'unremovable', // record cannot be removed
}

export enum ExposureStatus {
  New, // TODO: this will be removed with New Dashboard
  Pending,
  Removed,
}

export class Exposure {
  public id: number
  @observable public dataBrokerName: string
  @observable public status: ExposureStatus
  @observable public name: string
  @observable public phone: string
  @observable public address: string
  @observable public email: string
  @observable public age: string
  @observable public url: string
  @observable public associates: string[]
  @observable public relatives: string[]
  @observable public otherNames: string[]
  @observable public otherPhones: string[]
  @observable public otherAddresses: string[]
  @observable public otherEmails: string[]

  public constructor({
    id,
    dataBrokerName,
    status,
    name,
    phone,
    address,
    email,
    age,
    url,
    associates,
    relatives,
    otherNames,
    otherPhones,
    otherAddresses,
    otherEmails,
  }: {
    id: number
    dataBrokerName: string
    status: RawExposureStatus
    name: string
    phone: string
    address: string
    email: string
    age: string
    url: string
    associates: string[]
    relatives: string[]
    otherNames: string[]
    otherPhones: string[]
    otherAddresses: string[]
    otherEmails: string[]
  }) {
    this.id = id
    this.dataBrokerName = dataBrokerName
    this.name = name
    this.phone = phone
    this.address = address
    this.email = email
    this.age = age
    this.url = url
    this.associates = associates
    this.relatives = relatives
    this.otherNames = otherNames
    this.otherPhones = otherPhones
    this.otherAddresses = otherAddresses
    this.otherEmails = otherEmails

    switch (status) {
      case 'removing':
      case 'removed':
      case 'failed':
        this.status = ExposureStatus.Pending
        break

      case 'solved':
      case 'unremovable':
        this.status = ExposureStatus.Removed
        break

      default:
        this.status = ExposureStatus.Pending
        break
    }
  }
}

export default class ExposureStore {
  private static RETRY_FREQUENCY = 2000

  @observable public requestState: RequestState = 'not started'
  private readonly _exposures = observable<Exposure>([])

  @computed public get exposures(): Exposure[] {
    return this._exposures.slice().sort((a, b): number => {
      if (a.status < b.status) {
        return 1
      }

      if (a.status > b.status) {
        return -1
      }

      return 0
    })
  }

  @computed public get nTotalExposures(): number {
    return this.exposures.length
  }

  @computed public get nNewExposures(): number {
    return this.exposures.filter((exposure): boolean => exposure.status === ExposureStatus.New)
      .length
  }

  @computed public get pendingDeletionExposures(): Exposure[] {
    return this.exposures.filter((exposure): boolean => exposure.status === ExposureStatus.Pending)
  }

  @computed public get nPendingDeletionExposures(): number {
    return this.pendingDeletionExposures.length
  }

  @computed public get deletedExposures(): Exposure[] {
    return this.exposures.filter((exposure): boolean => exposure.status === ExposureStatus.Removed)
  }

  @computed public get nDeletedExposures(): number {
    return this.deletedExposures.length
  }

  private transportLayer: TransportLayer
  private sessionStore: SessionStore

  public constructor(transportLayer: TransportLayer, sessionStore: SessionStore) {
    this.transportLayer = transportLayer
    this.sessionStore = sessionStore

    // Listen to email and publicEmail changes and reset
    reaction(() => `${this.sessionStore.user?.email}${this.sessionStore.publicEmail}`, this.reset)
  }

  public getExposureByID = (id: number): Exposure | undefined =>
    this.exposures.find(exposure => exposure.id === id)

  public getExposures = async (): Promise<void> => {
    try {
      if (!this.sessionStore.abilities.can('read', 'Exposure')) {
        throw new Error(
          "getExposures shouldn't be called for user without the `read` `exposure` ability",
        )
      }

      runInAction((): void => {
        this.requestState = 'loading'
      })

      const exposures = await this.pollForExposures()

      runInAction((): void => {
        this.requestState = 'success'
        this._exposures.replace(exposures)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  private pollForExposures = async (): Promise<Exposure[]> => {
    try {
      const response = await this.transportLayer.getExposedRecords()

      if (Object.prototype.hasOwnProperty.call(response, 'status')) {
        await this.waitForRetry()
        return this.pollForExposures()
      }

      return response.map(
        rawExposure =>
          new Exposure({
            id: rawExposure.id,
            dataBrokerName: rawExposure.broker_domain,
            status: rawExposure.status,
            name: rawExposure.names[0] || '',
            phone: rawExposure.phones[0] || '',
            address: rawExposure.addresses[0] || '',
            email: rawExposure.emails[0] || '',
            age: rawExposure.age || '',
            url: rawExposure.urls ? rawExposure.urls[0] : '',
            associates: rawExposure.associates || [],
            relatives: rawExposure.relatives || [],
            otherNames: rawExposure.names.slice(1),
            otherPhones: rawExposure.phones.slice(1),
            otherAddresses: rawExposure.addresses.slice(1),
            otherEmails: rawExposure.emails.slice(1),
          }),
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, ExposureStore.RETRY_FREQUENCY))

  @action private reset = (): void => {
    this.requestState = 'not started'
    this._exposures.replace([])
  }
}
