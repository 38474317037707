import { observable, runInAction } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, {
  RequestState,
  DataBreachScanResult,
} from 'spartacus/services/TransportLayer'

export default class DataBreachScanResultsStore {
  @observable public requestState: RequestState = 'not started'
  public readonly scanResults = observable<DataBreachScanResult>([])

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  public getScanResults = async (): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })
      const scanResults = await this.transportLayer.getDataBreachScanResults()

      runInAction((): void => {
        this.requestState = 'success'
        this.scanResults.replace(scanResults)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  public getPublicScanResults = async (email: string): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })
      const scanResults = await this.transportLayer.getPublicDataBreachScanResults(email)

      runInAction((): void => {
        this.requestState = 'success'
        this.scanResults.replace(scanResults)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }
}
