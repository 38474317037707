import { observable, runInAction } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, { RequestState } from 'spartacus/services/TransportLayer'

interface DataBroker {
  id: string
  name: string
}

export default class DataBrokerStore {
  @observable public requestState: RequestState = 'not started'
  public readonly dataBrokers = observable<DataBroker>([])

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  public getDataBrokers = async (): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })
      const dataBrokers = await this.transportLayer.getDataBrokerList()

      runInAction((): void => {
        this.requestState = 'success'
        this.dataBrokers.replace(dataBrokers)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }
}
