import { Severity, PrivacySeverity } from 'spartacus/types/__generated_graph'
import {
  GetScoreHistoryResponseBody,
  ScoreHistoryResponseBody,
} from 'spartacus/services/TransportLayer'
import Colors, { Color } from 'spartacus/styles/colors'

const N_MONTHS_PRIOR_CURRENT_DATE = 2

export const getScoreHistoryStartDate = (): Date =>
  new Date(
    new Date().getFullYear(),
    new Date().getMonth() - N_MONTHS_PRIOR_CURRENT_DATE,
    new Date().getDate(),
  )

export const getOldScoreHistory = (
  scoreHistory: GetScoreHistoryResponseBody | undefined,
  date: Date,
): ScoreHistoryResponseBody | undefined =>
  Array.isArray(scoreHistory) && scoreHistory.length > 0
    ? scoreHistory?.reverse().find(s => new Date(s.created_at) <= date)
    : undefined

export const addScoreHistoryDataPoint = (
  scoreHistory: GetScoreHistoryResponseBody | undefined,
  privacyScore: number,
): ScoreHistoryResponseBody[] | undefined => {
  if (scoreHistory === undefined) {
    return undefined
  }

  const today = new Date()
  const hasCurrentDate =
    scoreHistory &&
    scoreHistory.some(s => {
      const scoreDate = new Date(s.created_at)

      return (
        scoreDate.getFullYear() === today.getFullYear() &&
        scoreDate.getMonth() === today.getMonth() &&
        scoreDate.getDate() === today.getDate()
      )
    })

  return hasCurrentDate
    ? scoreHistory
    : [
        ...scoreHistory,
        {
          created_at: new Date().toString(),
          event: 'internal_broker_scan.resolved',
          score: privacyScore,
        },
      ]
}

export const formatLineChartData = (
  scoreHistory: GetScoreHistoryResponseBody | undefined,
  privacyScore: number,
): GetScoreHistoryResponseBody | undefined => {
  const startDate = getScoreHistoryStartDate()
  const recentScoreHistory =
    Array.isArray(scoreHistory) && scoreHistory.length > 0
      ? scoreHistory?.filter(s => new Date(s.created_at) >= startDate)
      : undefined

  const oldestScoreHistory = getOldScoreHistory(scoreHistory, startDate)

  const isFirstDay =
    recentScoreHistory &&
    recentScoreHistory.length > 0 &&
    new Date(recentScoreHistory[0].created_at).getDate() === 1

  const newestScoreHistory =
    oldestScoreHistory && recentScoreHistory && !isFirstDay
      ? [
          {
            created_at: new Date(
              new Date().getFullYear(),
              new Date().getMonth() - N_MONTHS_PRIOR_CURRENT_DATE,
              1,
            ).toString(),
            event: oldestScoreHistory.event,
            score: oldestScoreHistory.score,
          },
          ...recentScoreHistory,
        ]
      : recentScoreHistory

  return addScoreHistoryDataPoint(newestScoreHistory, privacyScore)
}

export const maskPassword = (password: string): string => {
  return password
    .split('')
    .map(() => '•')
    .join('')
}

export const chopEnd = (str: string, char: string): string => {
  return str.slice(-1) === char ? str.slice(0, -1) : str
}

export const matchLocationPathnames = (pathnames: string[]): boolean => {
  return pathnames.includes(chopEnd(window.location.pathname, '/'))
}

export const centsToDollarString = (cents: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(cents / 100)

export const getSeverityByRiskScore = (riskScore?: number | null): Severity => {
  if (typeof riskScore !== 'number') {
    return Severity.Unknown
  }

  if (riskScore >= 71) {
    return Severity.High
  }

  if (riskScore >= 21) {
    return Severity.Medium
  }

  return Severity.Low
}

export const getPrivacyColorByScore = (riskScore?: number | null): Color => {
  if (typeof riskScore !== 'number') {
    return Colors.Brand.Primary1
  }

  if (riskScore >= 71) {
    return Colors.Brand.Primary1
  }

  if (riskScore >= 20) {
    return Colors.Brand.Secondary1
  }

  return Colors.Brand.Secondary5
}

export const getSeverityByPrivacyScore = (riskScore?: number | null): PrivacySeverity => {
  if (typeof riskScore !== 'number') {
    return PrivacySeverity.Unknown
  }

  if (riskScore >= 71) {
    return PrivacySeverity.Great
  }

  if (riskScore >= 30) {
    return PrivacySeverity.Decent
  }

  return PrivacySeverity.NeedsWork
}

export const getCreditRatingByScore = (score: number): string => {
  if (score >= 781) return 'Very Good'
  if (score >= 661) return 'Good'
  if (score >= 601) return 'Fair'
  if (score >= 500) return 'Poor'

  return 'Very Poor'
}

export const storybookColors: { [key: string]: string } = {
  ...Object.entries(Colors.Brand).reduce(
    (sum, [key, value]) => ({ ...sum, [`Brand.${key}`]: value }),
    {},
  ),
  ...Object.entries(Colors.UI).reduce(
    (sum, [key, value]) => ({ ...sum, [`UI.${key}`]: value }),
    {},
  ),
  ...Object.entries(Colors.Type).reduce(
    (sum, [key, value]) => ({ ...sum, [`Type.${key}`]: value }),
    {},
  ),
}

export const isFacebookApp = (): boolean => {
  const ua: string = navigator.userAgent || navigator.vendor
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
}
