import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { createInstance as createOptimizelyInstance } from '@optimizely/react-sdk'
import { configure as configureMobx } from 'mobx'
import { useStaticRendering as useMobxStaticRendering } from 'mobx-react'
import ApolloClient, { InMemoryCache } from 'apollo-boost'
import fetch from 'cross-fetch'
import AccountSettingsStore from 'spartacus/stores/AccountSettingsStore'
import AnalyticsStore from 'spartacus/stores/AnalyticsStore'
import CartStore from 'spartacus/stores/CartStore'
import DataBrokerStore from 'spartacus/stores/DataBrokerStore'
import DataBreachScanResultsStore from 'spartacus/stores/DataBreachScanResultsStore'
import ExposedPasswordStore from 'spartacus/stores/ExposedPasswordStore'
import ExposureStore from 'spartacus/stores/ExposureStore'
import ExposureSummaryStore from 'spartacus/stores/ExposureSummaryStore'
import KnownAssociatesStore from 'spartacus/stores/KnownAssociatesStore'
import PreviewScanStore from 'spartacus/stores/PreviewScanStore'
import PrivacyScoreStore from 'spartacus/stores/PrivacyScoreStore'
import ProductStore from 'spartacus/stores/ProductStore'
import ScoreHistoryStore from 'spartacus/stores/ScoreHistoryStore'
import SessionStore from 'spartacus/stores/SessionStore'
import Constants from 'spartacus/constants'
import SessionStorage from 'spartacus/services/SessionStorage'
import TransportLayer from 'spartacus/services/TransportLayer'
import { Severity } from 'spartacus/types/__generated_graph'
import clientSchema from 'spartacus/client-schema.graphql'

const cache = new InMemoryCache()
const defaultCacheData = {
  data: {
    severity: Severity.Unknown,
  },
}

export const optimizelyClient = createOptimizelyInstance({
  sdkKey: Constants.OPTIMIZELY_KEY,
  logLevel: Constants.ENVIRONMENT === 'production' ? 4 : 2,
})

export const apolloClient = new ApolloClient<{}>({
  uri: Constants.GRAPH_API_URL,
  cache,
  fetch,
  request: (operation): void => {
    const token = SessionStorage.get('spartacusToken')
    const transactionID = SessionStorage.get('transactionID')
    const headers: Record<string, string> = {}

    if (transactionID) {
      headers['X-Transaction-ID'] = transactionID
    }

    if (token) {
      headers.authorization = `Bearer ${token}`
    }

    operation.setContext({
      headers,
    })
  },
  name: 'web',
  version: `${Constants.ENVIRONMENT}_${Constants.RELEASE_VERSION}`,
  typeDefs: clientSchema,
  onError: ({ networkError, graphQLErrors }): void => {
    if (networkError) {
      Sentry.captureException(networkError)
    }

    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        if (graphQLError.message !== 'No error message') {
          Sentry.withScope(scope => {
            scope.setExtras(graphQLError)
            Sentry.captureException(new Error(graphQLError.message))
          })
        }
      }
    }
  },
  // resolvers: {
  //   Query: {
  //     riskScore: (): number => 42,
  //   },
  // },
})

apolloClient.cache.writeData(defaultCacheData)
apolloClient.onResetStore(
  async (): Promise<void> => {
    apolloClient.cache.writeData(defaultCacheData)
  },
)

configureMobx({
  enforceActions: 'observed',
})

if (!Constants.IS_BROWSER) {
  useMobxStaticRendering(true)
}

const transportLayer = new TransportLayer(apolloClient)
const sessionStore = new SessionStore(transportLayer, apolloClient, optimizelyClient)
const dataBrokerStore = new DataBrokerStore(transportLayer)
const exposedPasswordStore = new ExposedPasswordStore(transportLayer, sessionStore)
const exposureSummaryStore = new ExposureSummaryStore(transportLayer, sessionStore)
const exposureStore = new ExposureStore(transportLayer, sessionStore)
const productStore = new ProductStore()
const previewScanStore = new PreviewScanStore(transportLayer)
const privacyScoreStore = new PrivacyScoreStore(transportLayer)
const scoreHistoryStore = new ScoreHistoryStore(transportLayer)
const knownAssociatesStore = new KnownAssociatesStore(transportLayer)
const accountSettingsStore = new AccountSettingsStore(transportLayer)
const analyticsStore = new AnalyticsStore(sessionStore, apolloClient, optimizelyClient)
const cartStore = new CartStore(transportLayer, sessionStore, analyticsStore)
const dataBreachScanResultsStore = new DataBreachScanResultsStore(transportLayer)

export const stores = {
  sessionStore,
  dataBrokerStore,
  dataBreachScanResultsStore,
  exposedPasswordStore,
  exposureSummaryStore,
  exposureStore,
  productStore,
  cartStore,
  previewScanStore,
  accountSettingsStore,
  analyticsStore,
  privacyScoreStore,
  scoreHistoryStore,
  knownAssociatesStore,
}

export const StoresContext = React.createContext(stores)
