import ColorModule from 'color'

export type Color = Brand | UI | Type

const util = {
  hover: (color: Color): Color =>
    ColorModule(color)
      .lighten(0.1)
      .string() as Color,

  pressed: (color: Color): Color =>
    ColorModule(color)
      .darken(0.1)
      .string() as Color,

  disabled: (color: Color): Color =>
    ColorModule(color)
      .grayscale()
      .negate()
      .fade(0.5)
      .string() as Color,

  fade: (color: Color, amount: number): Color =>
    ColorModule(color)
      .fade(amount)
      .string() as Color,

  lighten: (color: Color, amount: number): Color =>
    ColorModule(color)
      .lighten(amount)
      .string() as Color,

  darken: (color: Color, amount: number): Color =>
    ColorModule(color)
      .darken(amount)
      .string() as Color,
}

// New Style Guide colors
enum Brand {
  Primary1 = '#269C78',
  Primary2 = '#144F3D',
  Primary3 = '#DAF3EF',
  Primary4 = '#2B3735',
  Primary5 = '#F7F6F5',
  Primary6 = '#555F5D',
  Secondary1 = '#ECC100',
  Secondary2 = '#B5F6E4',
  Secondary3 = '#F9C0BB',
  Secondary4 = '#A0B0CC',
  Secondary5 = '#EC6354',
  Secondary6 = '#20343F',
  Secondary7 = '#BD8632',
  Secondary8 = '#9C86F7',
  Secondary9 = '#9F9F9F',
}

enum UI {
  Black = '#000000',
  White = '#FFFFFF',
  Background = '#D3D3D3',
  BackgroundBlue = '#ECF9F7',
  Rule = '#F0F2F6',
  Divider = '#DFE0DF',
  Disabled = '#EAEAEA',
  HoverPrimary = 'rgba(38, 156, 120, 0.8)',
  HoverSecondary = 'rgba(218, 243, 239, 0.8)',
  TemporaryLilac = '#7b6ec7',
  LunarRock = '#C5C5C5',
}

enum Type {
  Primary = '#2B2B2B',
  Secondary = '#767676',
}

export default {
  Brand,
  UI,
  Type,
  util,
}
