import { action, runInAction, observable, ObservableMap } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, {
  PreviewScanResponseBody,
  CreatePreviewScanRequestBody,
} from 'spartacus/services/TransportLayer'

export type RequestState =
  | 'not started'
  | 'started'
  | 'loading'
  | 'partial'
  | 'success'
  | 'created'
  | 'failure'
export type ExposureScan = ObservableMap<keyof PreviewScanResponseBody>

export default class PreviewScanStore {
  private static RETRY_FREQUENCY = 2000
  private static emptyScan = {
    id: 0,
    first_name: '',
    last_name: '',
    zip: '',
    city: '',
    state_code: '',
    created_at: '',
    status: 'in_progress',
    total_records: 0,
    total_results: 0,
    total_data_brokers: 0,
  }

  @observable public requestState: RequestState = 'not started'
  @observable public scan: ExposureScan = observable.map({
    ...PreviewScanStore.emptyScan,
  })

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @action public createPreviewScan = async ({
    email,
    first_name,
    last_name,
    zip,
  }: CreatePreviewScanRequestBody): Promise<void | PreviewScanResponseBody> => {
    try {
      runInAction((): void => {
        this.requestState = 'started'
      })

      const response: PreviewScanResponseBody = await this.transportLayer.createPreviewScan({
        email,
        first_name,
        last_name,
        zip,
      })

      runInAction((): void => {
        this.requestState = 'created'
      })

      return response
    } catch (e) {
      if (e.response.status === 409) {
        runInAction((): void => {
          this.requestState = 'created'
        })

        return e.response.data
      }

      Sentry.captureException(e)

      runInAction((): void => {
        this.requestState = 'failure'
      })

      return Promise.reject(e)
    }
  }

  @action public getPreviewScan = async (email: string): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })

      const scan = await this.pollForPreviewScan(email)

      runInAction((): void => {
        this.requestState = 'success'
        this.scan.replace(scan)
      })
    } catch (e) {
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  private pollForPreviewScan = async (email: string): Promise<PreviewScanResponseBody> => {
    try {
      const scan = await this.transportLayer.getPreviewScan(email)

      runInAction((): void => {
        this.requestState = 'partial'
        this.scan.replace(scan)
      })

      if (scan.status !== 'finished') {
        await this.waitForRetry()
        return this.pollForPreviewScan(email)
      }

      return scan as PreviewScanResponseBody
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, PreviewScanStore.RETRY_FREQUENCY))
}
