export const varsFromEnv = {
  AUTH0_API_URL: process.env.AUTH0_API_URL as string,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE as string,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID as string,
  AUTH0_REDIRECT_URL: process.env.AUTH0_REDIRECT_URL as string,
  AUTH0_REALM: process.env.AUTH0_REALM as string,
  DATADOG_TOKEN: process.env.DATADOG_TOKEN as string,
  ENVIRONMENT: process.env.ENVIRONMENT as string,
  GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID as string,
  GRAPH_API_URL: process.env.GRAPH_API_URL as string,
  OPTIMIZELY_KEY: process.env.OPTIMIZELY_KEY as string,
  PRODUCT_FULL_ID: process.env.PRODUCT_FULL_ID as string,
  PRODUCT_FULL_PLAN_MONTHLY_ID: process.env.PRODUCT_FULL_PLAN_MONTHLY_ID as string,
  PRODUCT_FULL_PLAN_YEARLY_ID: process.env.PRODUCT_FULL_PLAN_YEARLY_ID as string,
  RECAPTCHA_CLIENT_SITE_KEY: process.env.RECAPTCHA_CLIENT_SITE_KEY as string,
  RELEASE_VERSION: (process.env.RELEASE_VERSION as string) || 'local',
  SENTRY_DSN: process.env.SENTRY_DSN as string,
  SPARTACUS_API_URL: process.env.SPARTACUS_API_URL as string,
  STRIPE_API_KEY: process.env.STRIPE_API_KEY as string,
  PUBLIC_DASHBOARD_A_B_TEST_01_ID: process.env.PUBLIC_DASHBOARD_A_B_TEST_01_ID as string,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY as string,
}

Object.entries(varsFromEnv).forEach(([key, value]): void => {
  if (!value || !value.length) {
    throw new Error(`${key} not set`)
  }
})

export const featuresFromEnv = {
  // 2021-08-30: Disabling preview scan tile by default as it depends on OneRep API
  ENABLE_PREVIEW_SCAN: ((process.env.ENABLE_PREVIEW_SCAN as string) || 'false') === 'true',
  // 2021-09-22: Disabling premium signup as we are going to cut-off OneRep API w/o proper replacement
  ENABLE_PREMIUM_SIGNUP: ((process.env.ENABLE_PREMIUM_SIGNUP as string) || 'false') === 'true',
}

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const generalSettings = {
  IS_BROWSER: process.browser,
  BREAKPOINTS: new Map<Breakpoint, number>([
    ['xs', 0],
    ['sm', 576],
    ['md', 768],
    ['lg', 992],
    ['xl', 1200],
    ['xxl', 1440],
  ]),
}

export type USState = {
  label: string
  value: string
}

const usStates: USState[] = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
]

export default {
  ...varsFromEnv,
  ...featuresFromEnv,
  ...generalSettings,
  usStates,
}
