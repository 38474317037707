import { action, observable, runInAction, ObservableMap, reaction, computed } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, {
  RequestState,
  GetExposedDataSummaryResponseSuccessBody,
} from 'spartacus/services/TransportLayer'
import SessionStore from 'spartacus/stores/SessionStore'

export type ExposureSummary = ObservableMap<keyof GetExposedDataSummaryResponseSuccessBody>

export default class ExposureSummaryStore {
  private static RETRY_FREQUENCY = 2000
  private static emptyExposureSummary = {
    emails: 0,
    relationships: 0,
    addresses: 0,
    social_profiles: 0,
    usernames: 0,
    mobile_phones: 0,
    jobs: 0,
    educations: 0,
    images: [],
  }

  @observable public requestState: RequestState = 'not started'
  @observable public exposureSummary: ExposureSummary = observable.map({
    ...ExposureSummaryStore.emptyExposureSummary,
  })

  @computed public get hasExposures(): boolean {
    return Object.values(this.exposureSummary.toJSON()).some(x => x > 0)
  }

  private transportLayer: TransportLayer
  private sessionStore: SessionStore

  public constructor(transportLayer: TransportLayer, sessionStore: SessionStore) {
    this.transportLayer = transportLayer
    this.sessionStore = sessionStore

    // Listen to email and publicEmail changes and reset
    reaction(() => `${this.sessionStore.user?.email}${this.sessionStore.publicEmail}`, this.reset)
  }

  public getExposureSummary = async (): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })

      const exposureSummary = await this.pollForExposureSummary()

      runInAction((): void => {
        this.requestState = 'success'
        this.exposureSummary.replace(exposureSummary)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  private pollForExposureSummary = async (): Promise<GetExposedDataSummaryResponseSuccessBody> => {
    try {
      const email = this.sessionStore.user ? undefined : this.sessionStore.publicEmail
      const response = await this.transportLayer.getExposedDataSummary(email)

      if (Object.prototype.hasOwnProperty.call(response, 'status')) {
        await this.waitForRetry()
        return this.pollForExposureSummary()
      }

      return response as GetExposedDataSummaryResponseSuccessBody
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, ExposureSummaryStore.RETRY_FREQUENCY))

  @action private reset = (): void => {
    this.requestState = 'not started'
    this.exposureSummary.replace({ ...ExposureSummaryStore.emptyExposureSummary })
  }
}
