import { computed } from 'mobx'
import Constants from 'spartacus/constants'
import { centsToDollarString } from 'spartacus/utils'

type BillingFrequency = 'month' | 'year'

export class PricingPlan {
  public readonly id: string
  public readonly name: string
  public readonly billingFrequency: BillingFrequency
  public readonly amountInCents: number
  public readonly onSale: boolean
  public readonly saleAmountInCents?: number
  public readonly salePercentSavingsString?: string

  @computed public get formattedOriginalPrice(): string {
    return centsToDollarString(this.amountInCents)
  }

  @computed public get formattedSalePrice(): string {
    return this.saleAmountInCents ? centsToDollarString(this.saleAmountInCents) : ''
  }

  @computed public get formattedPrice(): string {
    return this.formattedSalePrice || this.formattedOriginalPrice
  }

  @computed public get dollarAmountString(): string {
    return this.onSale && this.saleAmountInCents
      ? `${(this.saleAmountInCents / 100).toFixed(2)}`
      : `${(this.amountInCents / 100).toFixed(2)}`
  }

  public constructor({
    id,
    name,
    billingFrequency,
    amountInCents,
    onSale = false,
    saleAmountInCents,
    salePercentSavingsString,
  }: {
    id: string
    name: string
    billingFrequency: BillingFrequency
    amountInCents: number
    onSale?: boolean
    saleAmountInCents?: number
    salePercentSavingsString?: string
  }) {
    this.id = id
    this.name = name
    this.billingFrequency = billingFrequency
    this.amountInCents = amountInCents
    this.onSale = onSale
    this.saleAmountInCents = saleAmountInCents
    this.salePercentSavingsString = salePercentSavingsString
  }
}

export class Product {
  public readonly id: string
  public readonly name: string
  public readonly features: string[]
  public readonly monthlyPlan: PricingPlan
  public readonly yearlyPlan: PricingPlan

  public constructor({
    id,
    name,
    features,
    monthlyPlan,
    yearlyPlan,
  }: {
    id: string
    name: string
    features: string[]
    monthlyPlan: PricingPlan
    yearlyPlan: PricingPlan
  }) {
    this.id = id
    this.name = name
    this.features = features
    this.monthlyPlan = monthlyPlan
    this.yearlyPlan = yearlyPlan
  }
}

export default class ProductStore {
  public full: Product

  public constructor() {
    this.full = new Product({
      id: Constants.PRODUCT_FULL_ID,
      name: 'Spartacus Premium',
      features: [
        'Privacy Score',
        'Password Monitoring',
        'Data Deletion',
        'Credit Monitoring*',
        'Identity Theft Insurance†',
        'Fraud Restoration',
      ],
      monthlyPlan: new PricingPlan({
        id: Constants.PRODUCT_FULL_PLAN_MONTHLY_ID,
        name: 'Monthly',
        billingFrequency: 'month',
        amountInCents: 2999,
        onSale: true,
        saleAmountInCents: 1999,
        salePercentSavingsString: '34%',
      }),
      yearlyPlan: new PricingPlan({
        id: Constants.PRODUCT_FULL_PLAN_YEARLY_ID,
        name: 'Yearly',
        billingFrequency: 'year',
        amountInCents: 24900,
        onSale: true,
        saleAmountInCents: 9900,
        salePercentSavingsString: '60%',
      }),
    })
  }

  public getProductByPlanID(planID: string): Product | null {
    if (this.full.monthlyPlan.id === planID || this.full.yearlyPlan.id === planID) {
      return this.full
    }

    return null
  }

  public getPlanByPlanID(planID: string): PricingPlan | null {
    if (this.full.monthlyPlan.id === planID) {
      return this.full.monthlyPlan
    }

    if (this.full.yearlyPlan.id === planID) {
      return this.full.yearlyPlan
    }

    return null
  }
}
