type Key =
  | 'spartacusToken'
  | 'publicEmail'
  | 'authentication'
  | 'transactionID'
  | 'signUpSessionID'
  | 'signUpSubscriptionID'
  | 'signUpVerifyEmailToken'
  | 'signUpVerifyEmailComplete'
  | 'invitationToken'
  | 'testingControlPanelUserStateIndex'
  | 'testUser'
  | 'onboarding'

export const get = (key: Key): string | undefined =>
  typeof window !== 'undefined' ? window.sessionStorage.getItem(key) || undefined : undefined

export const set = (key: Key, value: string): void =>
  typeof window !== 'undefined' ? window.sessionStorage.setItem(key, value) : undefined

export const remove = (key: Key): void =>
  typeof window !== 'undefined' ? window.sessionStorage.removeItem(key) : undefined

export const getDashboardLoadingMessages = (): string[] | undefined => {
  if (typeof window === 'undefined') {
    return undefined
  }

  let messages: string[] | undefined

  try {
    const jsonString = window.sessionStorage.getItem('dashboardLoadingMessages')

    if (!jsonString) return undefined

    messages = JSON.parse(jsonString)

    if (!Array.isArray(messages)) return undefined
    if (!messages.every(value => typeof value === 'string')) return undefined
  } catch (e) {
    return undefined
  }

  return messages
}

export const setDashboardLoadingMessages = (messages: string[]): void =>
  typeof window !== 'undefined'
    ? window.sessionStorage.setItem('dashboardLoadingMessages', JSON.stringify(messages))
    : undefined

export const removeDashboardLoadingMessages = (): void =>
  typeof window !== 'undefined'
    ? window.sessionStorage.removeItem('dashboardLoadingMessages')
    : undefined

export default {
  get,
  set,
  remove,
  getDashboardLoadingMessages,
  setDashboardLoadingMessages,
  removeDashboardLoadingMessages,
}
