import { Ability as BaseAbility, AbilityClass, RawRuleOf } from '@casl/ability'
import { computed, observable, action, runInAction, reaction } from 'mobx'
import { ReactSDKClient } from '@optimizely/react-sdk'
import auth0 from 'auth0-js'
import * as Sentry from '@sentry/browser'
import ApolloClient from 'apollo-boost'
import Constants from 'spartacus/constants'
import TransportLayer, {
  GetUserRegistrationStatusResponseBody,
  CreateSignUpSessionRequestBody,
  UpdateSignUpSessionRequestBody,
  VerifyEmailTokenResponseBody,
} from 'spartacus/services/TransportLayer'
import SessionStorage from 'spartacus/services/SessionStorage'
import CookieService from 'spartacus/services/CookieService'
import { logger } from 'spartacus/services/LoggerService'
import { BillingSubscriptionPlanType, CreditStatus } from '../types/__generated_graph'

export type Ability =
  | ['read', 'Account']
  | ['create', 'Subscription']
  | ['read', 'Pre-Purchase Public Dashboard']
  | ['read', 'Freemium Dashboard']
  | ['read', 'Full-Protection Dashboard']
  | ['read', 'Billing Issues Dashboard']
  | ['read', 'Password']
  | ['read', 'Exposure']
  | ['read', 'Data Breach With Password']
  | ['read', 'Data Breach Without Password']
  | ['read', 'Credit Service']
export type AppAbilities = BaseAbility<Ability>
const AppAbilities = BaseAbility as AbilityClass<AppAbilities>

interface Auth0UserMeta {
  email: string
  full_name: string
  first_name?: string
  last_name?: string
  address_1?: string
  address_2?: string
  city?: string
  state?: string
  status: BillingStatus
  stripe_id: string
  zip: string
  date_of_birth: string
  name: string
  picture: string
  credit_status?: CreditStatus
  test_user?: boolean
}

export type Auth0Role = 'subscriber' | 'administrator'

interface Auth0IDToken {
  'http://custom-claims/roles': Auth0Role[]
  'http://custom-claims/user_meta': Auth0UserMeta
  nickname: string
  name: string
  picture: string
  updated_at: string
  email: string
  email_verified: boolean
  iss: string
  sub: string
  aud: string
  iat: number
  exp: number
  at_hash: string
  nonce: string
}

interface Auth0AuthResult {
  accessToken: string
  idToken: string
  idTokenPayload: Auth0IDToken
  appState: string
  refreshToken: null
  state: string
  expiresIn: number
  tokenType: 'Bearer'
  scope: string
}

export enum BillingStatus {
  free = 'free',
  active = 'active',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
  trialing = 'trialing',
}

export class User {
  @observable public email: string
  @observable public id: string
  @observable public fullName?: string
  @observable public firstName?: string
  @observable public lastName?: string
  @observable public dateOfBirth?: string
  @observable public address1?: string
  @observable public address2?: string
  @observable public city?: string
  @observable public state?: string
  @observable public zip?: string
  public readonly roles = observable<Auth0Role>([])
  @observable public avatarURI?: string
  @observable public stripeID?: string
  @observable public billingStatus?: BillingStatus
  @observable public creditStatus?: CreditStatus
  @observable public planType?: BillingSubscriptionPlanType
  @observable public testUser: boolean

  @computed public get dateOfBirthDate(): Date | undefined {
    if (!this.dateOfBirth) return undefined

    try {
      const [year, month, day] = this.dateOfBirth.split('-')
      const d = new Date()

      d.setFullYear(parseInt(year, 10))
      d.setMonth(parseInt(month, 10) - 1)
      d.setDate(parseInt(day, 10))

      return d
    } catch (e) {
      return undefined
    }
  }

  @computed public get hasActiveSubscription(): boolean {
    return Boolean(
      this.billingStatus &&
        [BillingStatus.active, BillingStatus.trialing].includes(this.billingStatus),
    )
  }

  @computed public get isUnsubscribed(): boolean {
    return this.billingStatus === BillingStatus.canceled
  }

  @computed public get hasBillingIssues(): boolean {
    return Boolean(
      this.billingStatus &&
        [
          BillingStatus.incomplete,
          BillingStatus.incomplete_expired,
          BillingStatus.past_due,
          BillingStatus.unpaid,
        ].includes(this.billingStatus),
    )
  }

  public constructor({
    email,
    id,
    fullName,
    firstName,
    lastName,
    dateOfBirth,
    address1,
    address2,
    city,
    state,
    zip,
    roles = [],
    avatarURI,
    stripeID,
    billingStatus,
    creditStatus,
    planType,
    testUser = false,
  }: {
    email: string
    id: string
    fullName?: string
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip?: string
    roles?: Auth0Role[]
    avatarURI?: string
    stripeID?: string
    billingStatus?: BillingStatus
    creditStatus?: CreditStatus
    planType?: BillingSubscriptionPlanType
    testUser: boolean
  }) {
    this.email = email
    this.id = id
    this.fullName = fullName
    this.firstName = firstName
    this.lastName = lastName
    this.dateOfBirth = dateOfBirth
    this.address1 = address1
    this.address2 = address2
    this.city = city
    this.state = state
    this.zip = zip
    this.roles.replace(roles)
    this.avatarURI = avatarURI
    this.stripeID = stripeID
    this.billingStatus = billingStatus
    this.creditStatus = creditStatus
    this.planType = planType
    this.testUser = testUser
  }
}

export default class SessionStore {
  private static defaultAbilities: RawRuleOf<AppAbilities>[] = [
    { action: 'create', subject: 'Subscription' },
  ]

  @observable public isAuthorizing = false
  @observable public fullyLoaded = false
  @observable public user?: User
  public abilities: AppAbilities

  @observable private _publicEmail = SessionStorage.get('publicEmail')
  @observable private _signUpSessionID = SessionStorage.get('signUpSessionID')
  @observable private _signUpSubscriptionID = SessionStorage.get('signUpSubscriptionID')
  @observable private _signUpVerifyEmailToken = SessionStorage.get('signUpVerifyEmailToken')
  @observable private _invitationToken = SessionStorage.get('invitationToken')

  private transportLayer: TransportLayer
  private apolloClient: ApolloClient<{}>
  private optimizelyClient: ReactSDKClient
  private auth0Client = new auth0.WebAuth({
    domain: Constants.AUTH0_API_URL,
    clientID: Constants.AUTH0_CLIENT_ID,
    redirectUri: Constants.AUTH0_REDIRECT_URL,
    responseType: 'token id_token',
    scope:
      'openid profile email http://custom-claims/roles http://custom-claims/app_meta http://custom-claims/user_meta',
    audience: Constants.AUTH0_AUDIENCE,
  })

  @computed public get publicEmail(): string | undefined {
    return this._publicEmail
  }

  public set publicEmail(email: string | undefined) {
    if (email) {
      this.transportLayer.authToken = undefined
      this.user = undefined
      this._publicEmail = email.toLowerCase()
      SessionStorage.set('publicEmail', email.toLowerCase())
    } else {
      this._publicEmail = undefined
      SessionStorage.remove('publicEmail')
    }
  }

  @computed public get signUpSessionID(): string | undefined {
    return this._signUpSessionID
  }

  public set signUpSessionID(id: string | undefined) {
    if (id) {
      this._signUpSessionID = id
      SessionStorage.set('signUpSessionID', id)
    } else {
      this._signUpSessionID = undefined
      SessionStorage.remove('signUpSessionID')
    }
  }

  @computed public get signUpSubscriptionID(): string | undefined {
    return this._signUpSubscriptionID
  }

  public set signUpSubscriptionID(id: string | undefined) {
    if (id) {
      this._signUpSubscriptionID = id
      SessionStorage.set('signUpSubscriptionID', id)
    } else {
      this._signUpSubscriptionID = undefined
      SessionStorage.remove('signUpSubscriptionID')
    }
  }

  @computed public get signUpVerificationToken(): string | undefined {
    return this._signUpVerifyEmailToken
  }

  public set signUpVerificationToken(token: string | undefined) {
    if (token) {
      this._signUpVerifyEmailToken = token
      SessionStorage.set('signUpVerifyEmailToken', token)
    } else {
      this._signUpVerifyEmailToken = undefined
      SessionStorage.remove('signUpVerifyEmailToken')
    }
  }

  @computed public get invitationToken(): string | undefined {
    return this._invitationToken
  }

  public set invitationToken(token: string | undefined) {
    if (token) {
      this._invitationToken = token
      SessionStorage.set('invitationToken', token)
    } else {
      this._invitationToken = undefined
      SessionStorage.remove('invitationToken')
    }
  }

  public constructor(
    transportLayer: TransportLayer,
    apolloClient: ApolloClient<{}>,
    optimizelyClient: ReactSDKClient,
  ) {
    this.transportLayer = transportLayer
    this.apolloClient = apolloClient
    this.optimizelyClient = optimizelyClient
    this.abilities = new BaseAbility<Ability>(SessionStore.defaultAbilities)

    this.updateAbilities()

    // Update abilities when user or publicEmail changes
    reaction(() => {
      return `${this.publicEmail}${this.user?.id}${this.user?.billingStatus}${this.user?.creditStatus}${this.user?.testUser}`
    }, this.updateAbilities)

    if (Constants.IS_BROWSER) {
      runInAction((): void => {
        this.isAuthorizing = true
      })

      if (this.transportLayer.authToken) {
        runInAction((): void => {
          this.isAuthorizing = true
          this.publicEmail = undefined
        })

        this.auth0Client.checkSession(
          {
            audience: Constants.AUTH0_AUDIENCE,
            scope:
              'openid profile email http://custom-claims/roles http://custom-claims/app_meta http://custom-claims/user_meta',
          },
          (err, result): void => {
            if (err) {
              this.updateAbilities()

              runInAction(() => {
                this.fullyLoaded = true
                this.isAuthorizing = false
              })

              return
            }

            this.parseAuthResult(result).then(() => {
              runInAction(() => {
                this.fullyLoaded = true
                this.isAuthorizing = false
              })
            })
          },
        )
      } else if (SessionStorage.get('testingControlPanelUserStateIndex')) {
        // Let TestingControlPanel handle the rest of the loading
      } else {
        this.updateAbilities()

        runInAction(() => {
          this.fullyLoaded = true
          this.isAuthorizing = false
        })
      }
    }
  }

  @action public reloadToken = (): Promise<void> =>
    new Promise((resolve, reject) => {
      if (this.transportLayer.authToken) {
        this.auth0Client.checkSession(
          {
            audience: Constants.AUTH0_AUDIENCE,
            scope:
              'openid profile email http://custom-claims/roles http://custom-claims/app_meta http://custom-claims/user_meta',
          },
          (err, result): void => {
            if (err) {
              return reject(err)
            }

            this.parseAuthResult(result)
              .then(resolve)
              .catch(reject)

            return undefined
          },
        )
      } else {
        return resolve()
      }

      return undefined
    })

  @action public logIn = (email: string, password: string): Promise<void> =>
    new Promise((resolve, reject): void => {
      runInAction((): void => {
        this.isAuthorizing = true
        this.publicEmail = email
      })

      this.auth0Client.login(
        {
          realm: Constants.AUTH0_REALM,
          username: email,
          password,
        },
        err => {
          runInAction((): void => {
            this.isAuthorizing = false
          })

          if (err) {
            this.reset()
            reject(err.error_description)

            return
          }

          resolve()
        },
      )
    })

  @action public requestOTP = (email: string): Promise<void> =>
    new Promise((resolve, reject): void => {
      runInAction((): void => {
        this.isAuthorizing = true
        this.publicEmail = email
      })

      this.auth0Client.passwordlessStart(
        {
          connection: 'email',
          send: 'code',
          email,
        },
        (err): void => {
          runInAction((): void => {
            this.isAuthorizing = false
          })

          if (err) {
            this.reset()
            reject()

            return
          }

          resolve()
        },
      )
    })

  @action public authenticateCode = (code: string): Promise<void> =>
    new Promise((resolve, reject): void => {
      runInAction((): void => {
        this.isAuthorizing = true
      })

      this.auth0Client.passwordlessLogin(
        {
          connection: 'email',
          email: this.publicEmail,
          verificationCode: code,
        },
        err => {
          runInAction((): void => {
            this.isAuthorizing = false
          })

          if (err) {
            logger.info('[code entry] error', err.original)
            reject()

            return
          }

          logger.info('[code entry] success')

          runInAction((): void => {
            this.publicEmail = undefined
          })

          resolve()
        },
      )
    })

  @action public authenticate = (): Promise<void> =>
    new Promise((resolve, reject): void => {
      runInAction((): void => {
        this.isAuthorizing = true
        this.publicEmail = undefined
      })

      this.auth0Client.parseHash(
        async (err, authResult): Promise<void> => {
          if (err || !authResult) {
            this.reset()
            return reject(err)
          }

          await this.parseAuthResult((authResult as unknown) as Auth0AuthResult)

          return resolve()
        },
      )
    })

  @action public logOut = (): void => {
    this.reset()
    this.auth0Client.logout({ returnTo: `${window.location.origin}/logged-out` })
  }

  @action public reset = (): void => {
    this.isAuthorizing = false
    this.transportLayer.authToken = undefined
    this.user = undefined
    this.publicEmail = undefined
    this.signUpSessionID = undefined
    this.signUpSubscriptionID = undefined
    this.signUpVerificationToken = undefined
    // Do not reset invitationToken, we need to persist it as much as we can
    // this.invitationToken = undefined
    Sentry.configureScope(scope => scope.setUser(null))
    if (typeof window !== 'undefined' && window.analytics) {
      window.analytics.reset()
    }
    this.apolloClient.resetStore()
  }

  @action public verifyEmailToken = async (): Promise<VerifyEmailTokenResponseBody> => {
    try {
      runInAction((): void => {
        this.isAuthorizing = true
      })

      if (!this.signUpVerificationToken) {
        throw new Error()
      }

      const response = await this.transportLayer.verifyEmailToken({
        token: this.signUpVerificationToken,
      })

      runInAction((): void => {
        this.isAuthorizing = false
      })

      return response
    } catch (e) {
      runInAction((): void => {
        this.isAuthorizing = false
      })

      return Promise.reject(e)
    }
  }

  @action public getUserRegistrationStatus = async (
    email: string,
  ): Promise<GetUserRegistrationStatusResponseBody> => {
    try {
      runInAction((): void => {
        this.isAuthorizing = true
      })

      const response = await this.transportLayer.getUserRegistrationStatus(email)

      runInAction((): void => {
        this.isAuthorizing = false
      })

      return response
    } catch (e) {
      runInAction((): void => {
        this.isAuthorizing = false
      })

      return Promise.reject(e)
    }
  }

  @action public sendForgotPasswordEmail = async (email: string): Promise<void> => {
    try {
      runInAction((): void => {
        this.isAuthorizing = true
      })

      await this.transportLayer.sendForgotPasswordEmail({
        client_id: Constants.AUTH0_CLIENT_ID,
        connection: Constants.AUTH0_REALM,
        email,
      })

      runInAction((): void => {
        this.isAuthorizing = false
      })

      return Promise.resolve()
    } catch (e) {
      runInAction((): void => {
        this.isAuthorizing = false
      })

      return Promise.reject(e)
    }
  }

  @action public createSignUpSession = async (
    type: 'freemium' | 'premium' | 'password' | undefined = undefined,
  ): Promise<void> => {
    try {
      const userSubscription = this.signUpSubscriptionID ? 'premium' : 'freemium'
      const sessionType = type || userSubscription

      const body: CreateSignUpSessionRequestBody = {
        session: {
          type: sessionType,
          email: this.publicEmail || '',
          invitation: this.invitationToken || '',
        },
      }

      if (this.signUpSubscriptionID) {
        body.session.subscription = this.signUpSubscriptionID
      }

      const { session_id: signUpSessionID } = await this.transportLayer.createSignUpSession(body)

      logger.debug('[user auth 2.0] create sign up session success.')

      runInAction(() => {
        this.signUpSessionID = signUpSessionID
      })
    } catch (e) {
      logger.debug(`[user auth 2.0] create sign up session error: ${e}`)
      // TODO: handle restart session
      throw new Error(e)
    }
  }

  @action public updateSignUpSession = async (
    data: UpdateSignUpSessionRequestBody,
  ): Promise<void> => {
    try {
      await this.transportLayer.updateSignUpSession(this.signUpSessionID || '', data)
    } catch (e) {
      // TODO: handle restart session
      throw new Error(e)
    }
  }

  private parseAuthResult = async (authResult: Auth0AuthResult): Promise<void> => {
    const { accessToken, idTokenPayload } = authResult
    const userMeta = idTokenPayload['http://custom-claims/user_meta']

    runInAction((): void => {
      this.transportLayer.authToken = accessToken
    })

    let planType: BillingSubscriptionPlanType | undefined
    let creditStatus: CreditStatus | undefined

    if ([BillingStatus.active, BillingStatus.trialing].includes(userMeta.status)) {
      try {
        const getPlanResponse = await this.transportLayer.getPlan()

        planType = getPlanResponse.data.billingProfile?.subscription?.plan?.type

        if (planType === BillingSubscriptionPlanType.Full) {
          const getCreditStatusResponse = await this.transportLayer.getCreditStatus()

          creditStatus = getCreditStatusResponse.data.creditStatus || undefined

          if (creditStatus !== userMeta.credit_status) {
            this.reloadToken()

            return
          }
        }
      } catch (e) {
        // Fail silently
      }
    }

    const user = new User({
      email: idTokenPayload.email,
      id: idTokenPayload.sub,
      fullName: userMeta.full_name,
      firstName: userMeta.first_name,
      lastName: userMeta.last_name,
      dateOfBirth: userMeta.date_of_birth,
      address1: userMeta.address_1,
      address2: userMeta.address_2,
      city: userMeta.city,
      state: userMeta.state,
      zip: userMeta.zip,
      roles: idTokenPayload['http://custom-claims/roles'],
      avatarURI: idTokenPayload.picture,
      stripeID: userMeta.stripe_id,
      billingStatus: userMeta.status,
      creditStatus,
      planType,
      testUser: Boolean(userMeta.test_user),
    })

    runInAction((): void => {
      this.user = user
      this.isAuthorizing = false
    })
  }

  private updateAbilities = (): void => {
    // Allow a user to opt-in to Optimizely feature flags as a testUser for this session
    // by appending ?test-user=true to the URL.
    // To opt-out append ?test-user=false
    if (typeof window !== 'undefined') {
      const params = new URL(window.document.location.toString()).searchParams

      if (params.get('test-user') === 'true') {
        SessionStorage.set('testUser', 'true')
      }

      if (params.get('test-user') === 'false') {
        SessionStorage.remove('testUser')
      }
    }

    SessionStorage.remove('signUpSessionID')
    SessionStorage.remove('signUpSubscriptionID')

    if (this.user) {
      // Authorized user
      let newAbilities: RawRuleOf<AppAbilities>[] = [
        ...SessionStore.defaultAbilities,
        { action: 'read', subject: 'Password' },
        { action: 'read', subject: 'Account' },
      ]

      if (this.user.roles.includes('subscriber')) {
        // Users with Stripe account have role "subscriber"
        if (
          this.user.billingStatus &&
          [BillingStatus.active, BillingStatus.trialing].includes(this.user.billingStatus)
        ) {
          // User has active billing
          newAbilities = newAbilities.concat([
            { action: 'create', subject: 'Subscription', inverted: true },
            { action: 'read', subject: 'Exposure' },
            { action: 'read', subject: 'Data Breach Without Password' },
            { action: 'read', subject: 'Data Breach With Password' },
          ])

          if (this.user.planType === 'full') {
            newAbilities.push({ action: 'read', subject: 'Full-Protection Dashboard' })
            newAbilities.push({ action: 'read', subject: 'Credit Service' })
          } else {
            // All Limited plans are now considered Freemium
            newAbilities = newAbilities.concat([
              { action: 'read', subject: 'Freemium Dashboard' },
              { action: 'read', subject: 'Exposure' },
              { action: 'read', subject: 'Data Breach Without Password' },
              { action: 'read', subject: 'Data Breach With Password' },
            ])
          }
        } else {
          // User has inactive billing
          newAbilities.push({ action: 'read', subject: 'Billing Issues Dashboard' })
        }
      } else {
        // Users without Stripe account does not have role "subscriber"
        newAbilities = newAbilities.concat([
          { action: 'read', subject: 'Freemium Dashboard' },
          { action: 'read', subject: 'Exposure' },
          { action: 'read', subject: 'Data Breach Without Password' },
          { action: 'read', subject: 'Data Breach With Password' },
        ])
      }

      this.abilities.update(newAbilities)

      this.optimizelyClient.setUser({
        id: this.user.id,
        attributes: {
          'test-user': this.user.testUser || SessionStorage.get('testUser') === 'true',
        },
      })
      Sentry.configureScope((scope): void => {
        scope.setUser({ id: this.user?.id, email: this.user?.email, abilities: newAbilities })
      })
      if (typeof window !== 'undefined' && window.analytics) {
        window.analytics.identify(this.user.id, {
          name: this.user.fullName,
          email: this.user.email,
          abilities: newAbilities,
          role: this.abilities.can('read', 'Full-Protection Dashboard') ? 'Premium' : 'Freemium',
        })
      }
      logger.addContext('userID', this.user.id)
      logger.addContext('userEmail', this.user.email)

      return
    }

    if (this.publicEmail) {
      const newAbilities: RawRuleOf<AppAbilities>[] = [
        ...SessionStore.defaultAbilities,
        { action: 'read', subject: 'Pre-Purchase Public Dashboard' },
        { action: 'read', subject: 'Data Breach Without Password' },
      ]

      this.abilities.update(newAbilities)
      this.optimizelyClient.setUser({
        id: CookieService.getDeviceID(),
        attributes: {
          'test-user': SessionStorage.get('testUser') === 'true',
        },
      })
      Sentry.configureScope((scope): void => {
        scope.setUser({ email: this.publicEmail, abilities: newAbilities })
      })
      if (typeof window !== 'undefined' && window.analytics) {
        window.analytics.identify({
          email: this.publicEmail,
          abilities: newAbilities,
        })
      }

      logger.addContext('userID', undefined)
      logger.addContext('userEmail', this.publicEmail)

      return
    }

    const newAbilities = [...SessionStore.defaultAbilities]

    this.abilities.update(newAbilities)
    this.optimizelyClient.setUser({
      id: CookieService.getDeviceID(),
      attributes: {
        'test-user': SessionStorage.get('testUser') === 'true',
      },
    })
    Sentry.configureScope((scope): void => {
      scope.setUser(null)
    })
    if (typeof window !== 'undefined' && window.analytics) {
      window.analytics.reset()
    }
    logger.addContext('userID', undefined)
    logger.addContext('userEmail', undefined)
  }
}
