import axios from 'axios'
import ApolloClient, { gql, ApolloQueryResult } from 'apollo-boost'
import { computed, observable } from 'mobx'
import Constants from 'spartacus/constants'
import { RawExposureStatus } from 'spartacus/stores/ExposureStore'
import SessionStorage from 'spartacus/services/SessionStorage'
import { BillingProfileQuery, CreditStatusQuery } from '../types/__generated_graph'

export type RequestState = 'not started' | 'loading' | 'partial' | 'success' | 'failure'
export type AuthenticationType = 'email' | 'password' | 'none'

export interface SendForgotPasswordEmailRequestBody {
  client_id: string
  email: string
  connection: string
}
export interface VerifyEmailTokenRequestBody {
  token: string
}

export interface VerifyEmailTokenResponseBody {
  session_id: string
}

export interface CreateSignUpSessionRequestBody {
  session: {
    type: 'freemium' | 'premium' | 'password'
    subscription?: string
    email: string
    invitation?: string
  }
}

export interface RegisterAssociateRequestBody {
  email: string
}

export interface CreateSignUpSessionResponseBody {
  session_id: string
  type: 'freemium' | 'premium' | 'password'
  status: 'pending' | 'open' | 'closed' | 'completed' | 'void'
}

export interface UpdateSignUpSessionRequestBody {
  user_details?: {
    first_name: string
    last_name: string
    date_of_birth: string
  }
  address?: {
    address_line_1: string
    address_line_2?: string
    city: string
    state_code: string
    zip: string
  }
  authentication?: {
    password: string
  }
}

export interface UpdateSignUpSessionResponseBody {
  session: string
  type: 'freemium' | 'premium'
  status: 'pending' | 'open' | 'closed' | 'completed'
  user_details?: {
    first_name: string
    last_name: string
    date_of_birth: string
  }
  address?: {
    address_line_1: string
    address_line_2?: string
    city: string
    state_code: string
    zip: string
  }
  authentication?: {
    password: string
  }
}

interface CreateBillingPortalSessionRequestBody {
  return_url: string
}

interface CreateBillingPortalSessionResponseBody {
  customer_portal_url: string
}

export interface CreateSubscriptionRequestBody {
  mode?: 'signup' // cspell: disable-line
  payment_method: string
  plan: string
  referral?: string
  promotion_code?: string
}

interface CreateCouponRequestBody {
  plan: string
  promotion_code: string
  email?: string
}

export interface CreatePreviewScanRequestBody {
  email: string
  first_name: string
  last_name: string
  zip: string
}

export interface PreviewScanResponseBody {
  id: number
  first_name: string
  last_name: string
  zip: string
  city: string
  state_code: string
  created_at: string
  status: 'pending' | 'in_progress' | 'finished'
  total_records: number
  total_results: number
  total_data_brokers: number
}

interface CreateSubscriptionResponseBody {
  subscription: string
  plan: string
}

export interface CreateCouponResponseBody {
  promotion_code: string
  original_price: number
  discounted_price: number
  coupon: {
    id: string
    name: string
    amount_off: number | null
    percent_off: number | null
    duration: 'once' | 'repeating' | 'forever'
    duration_in_months: number | null
  }
  plan: {
    id: string
  }
}

export type ExposedRecordsResponseBody = {
  id: number
  source: 'internal' | 'external'
  broker_domain: string
  status: RawExposureStatus
  age: string | null
  names: string[]
  phones: string[]
  addresses: string[]
  emails: string[]
  urls?: string[]
  associates: string[]
  relatives: string[]
  updated_at: string
  created_at: string
}

export type GetExposedRecordsResponseBody = ExposedRecordsResponseBody[]

export type GetExposedDataSummaryResponseSuccessBody = {
  emails: number
  relationships: number
  addresses: number
  social_profiles: number
  usernames: number
  mobile_phones: number
  jobs: number
  educations: number
  images: string[]
}

export type KnownAssociate = {
  id: number
  name: string
  email: string
  privacy_score: number
  profile_image_url: string | null
}

export type GetKnownAssociatesResponseBody = KnownAssociate[]

export type GetExposedDataSummaryResponseQueuedBody = {
  status: 'queued'
}

export type DataBreachScanResult = {
  id: number // 8
  title: string // "8tracks"
  description: string // "In June 2017, the online playlists"
  service_name: string | null // "8tracks.com"
  service_url: string | null // "https://8tracks.com/"
  breach_date: string // "2017-06-27"
  accounts_count: number // 17979961
  included_data: string // "Email addresses, Usernames, Passwords",
  leaked_email: string[] // ["testuser@mail.com", "testuser+1@mail.com"]
  leaked_passwords: string[] // ["pass1", "pass2"]
  leaked_hashes: string[] // ["hashedpassword1", "hashedpassword2"]}
}

export type GetDataBreachScanResultsResponseBody = DataBreachScanResult[]

export type GetExposedDataSummaryResponseBody =
  | GetExposedDataSummaryResponseSuccessBody
  | GetExposedDataSummaryResponseQueuedBody

export type GetExposedPasswordsResponseBody = string[] | { status: 'queued' }

type GetRiskScoreResponseBody =
  | {
      risk_score: number
    }
  | { status: 'queued' }

export type GetPrivacyScoreResponseBody =
  | {
      privacy_score: number
    }
  | { status: 'queued' }

export type ScoreHistoryResponseBody = {
  created_at: string
  event: string
  score: number
}

export type GetScoreHistoryResponseBody = ScoreHistoryResponseBody[]

interface ErrorResponseBody {
  message?: string
}

interface ValidatePhoneNumberResponseBody {
  phoneNumber: string
}

interface GetSubscriptionResponseBody {
  subscription: {
    amount: number
    plan_name: string
    current_period_end: number
    product_id: string
    product_name: string
  }
  billing_info: {
    type: 'card' | 'ideal' | 'sepa_debit'
    transaction_id: string
    billing_details: {
      postal_code?: string
    }
    card?: {
      brand: string
      last4: string
    }
  }
}

export interface GetUserRegistrationStatusResponseBody {
  registered: boolean
  mailing_list_subscription: boolean
  authentication: AuthenticationType
}

interface CreateUpdateSubscriptionSessionRequestBody {
  success_url: string
  cancel_url: string
}

interface CreateUpdateSubscriptionSessionResponseBody {
  id: string
}

type GetDataBrokerListResponseBody = {
  id: string
  name: string
}[]

type ResponseBody =
  | ErrorResponseBody
  | VerifyEmailTokenResponseBody
  | CreateSignUpSessionResponseBody
  | UpdateSignUpSessionResponseBody
  | ValidatePhoneNumberResponseBody
  | GetExposedDataSummaryResponseBody
  | GetExposedPasswordsResponseBody
  | GetRiskScoreResponseBody
  | GetPrivacyScoreResponseBody
  // | GetExposuresResponseBody
  | GetSubscriptionResponseBody
  | CreateUpdateSubscriptionSessionResponseBody
  | GetDataBrokerListResponseBody
  | GetUserRegistrationStatusResponseBody
  | CreateBillingPortalSessionResponseBody
  | {}

export default class TransportLayer {
  @observable private _authToken = SessionStorage.get('spartacusToken')

  @computed public get authToken(): string | undefined {
    return this._authToken
  }

  public set authToken(token: string | undefined) {
    this._authToken = token

    if (token) {
      SessionStorage.set('spartacusToken', token)
    } else {
      SessionStorage.remove('spartacusToken')
    }
  }

  private GENERIC_ERROR = 'Something went wrong. Please try again.'
  private apolloClient: ApolloClient<{}>

  public constructor(apolloClient: ApolloClient<{}>) {
    this.apolloClient = apolloClient
  }

  public getPlan = (): Promise<ApolloQueryResult<BillingProfileQuery>> =>
    this.apolloClient.query<BillingProfileQuery>({
      query: gql`
        query BillingProfile {
          billingProfile {
            subscription {
              plan {
                type
              }
            }
          }
        }
      `,
    })

  public sendForgotPasswordEmail = (data: SendForgotPasswordEmailRequestBody): Promise<{}> =>
    this.post<SendForgotPasswordEmailRequestBody>(
      `https://${Constants.AUTH0_API_URL}/dbconnections/change_password`,
      data,
    )

  public getCreditStatus = (): Promise<ApolloQueryResult<CreditStatusQuery>> =>
    this.apolloClient.query<CreditStatusQuery>({
      query: gql`
        query CreditStatus {
          creditStatus
        }
      `,
    })

  public verifyEmailToken = (
    data: VerifyEmailTokenRequestBody,
  ): Promise<VerifyEmailTokenResponseBody> =>
    this.post<VerifyEmailTokenRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/signups/verifications`, // cspell: disable-line
      data,
    ) as Promise<VerifyEmailTokenResponseBody>

  public createSignUpSession = (
    data: CreateSignUpSessionRequestBody,
  ): Promise<CreateSignUpSessionResponseBody> =>
    this.post<CreateSignUpSessionRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/signups`, // cspell: disable-line
      data,
    ) as Promise<CreateSignUpSessionResponseBody>

  public updateSignUpSession = (
    sessionID: string,
    data: UpdateSignUpSessionRequestBody,
  ): Promise<UpdateSignUpSessionResponseBody> =>
    this.patch<UpdateSignUpSessionRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/signups/${sessionID}`, // cspell: disable-line
      data,
    ) as Promise<UpdateSignUpSessionResponseBody>

  public createBillingPortalSession = (): Promise<CreateBillingPortalSessionResponseBody> =>
    this.post<CreateBillingPortalSessionRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/billing-portal-session`,
      {
        return_url: `${window.location.origin}/account`,
      },
    ) as Promise<CreateBillingPortalSessionResponseBody>

  public createSubscription = (
    data: CreateSubscriptionRequestBody,
  ): Promise<CreateSubscriptionResponseBody> =>
    this.post<CreateSubscriptionRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/subscription`,
      data,
    ) as Promise<CreateSubscriptionResponseBody>

  public createCoupon = (data: CreateCouponRequestBody): Promise<CreateCouponResponseBody> =>
    this.post<CreateCouponRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/discount`,
      data,
    ) as Promise<CreateCouponResponseBody>

  public registerAssociate = (
    associateId: number,
    associateData: RegisterAssociateRequestBody,
  ): Promise<{}> =>
    this.post<RegisterAssociateRequestBody>(
      `${Constants.SPARTACUS_API_URL}/dashboard/v1/associates/${associateId}/register`,
      associateData,
    ) as Promise<{}>

  public createPreviewScan = (
    data: CreatePreviewScanRequestBody,
  ): Promise<PreviewScanResponseBody> =>
    this.post<CreatePreviewScanRequestBody>(
      `${Constants.SPARTACUS_API_URL}/public-dashboard/v1/preview-scan`,
      data,
    ) as Promise<PreviewScanResponseBody>

  public getDataBrokerList = (): Promise<GetDataBrokerListResponseBody> =>
    this.get(`${Constants.SPARTACUS_API_URL}/public-dashboard/v1/data-brokers`) as Promise<
      GetDataBrokerListResponseBody
    >

  public getExposedRecords = (): Promise<GetExposedRecordsResponseBody> =>
    this.get(`${Constants.SPARTACUS_API_URL}/dashboard/v1/exposed-scanned-records`) as Promise<
      GetExposedRecordsResponseBody
    >

  public getExposedPasswords = (email?: string): Promise<GetExposedPasswordsResponseBody> => {
    const url = email
      ? `/public-dashboard/v1/exposed-passwords?email=${encodeURIComponent(email)}`
      : '/dashboard/v1/exposed-passwords'
    return this.get(`${Constants.SPARTACUS_API_URL}${url}`) as Promise<
      GetExposedPasswordsResponseBody
    >
  }

  public getDataBreachScanResults = (): Promise<GetDataBreachScanResultsResponseBody> => {
    return this.get(`${Constants.SPARTACUS_API_URL}/dashboard/v1/data-breaches`) as Promise<
      GetDataBreachScanResultsResponseBody
    >
  }

  public getPublicDataBreachScanResults = (
    email: string,
  ): Promise<GetDataBreachScanResultsResponseBody> => {
    return this.get(
      `${Constants.SPARTACUS_API_URL}/public-dashboard/v1/data-breaches?email=${encodeURIComponent(
        email,
      )}`,
    ) as Promise<GetDataBreachScanResultsResponseBody>
  }

  public getPreviewScan = (email: string): Promise<PreviewScanResponseBody> => {
    const url = `/public-dashboard/v1/preview-scan?email=${encodeURIComponent(email)}`
    return this.get(`${Constants.SPARTACUS_API_URL}${url}`) as Promise<PreviewScanResponseBody>
  }

  public getPrivacyScore = (): Promise<GetPrivacyScoreResponseBody> => {
    const url = '/dashboard/v1/privacy-score'
    return this.get(`${Constants.SPARTACUS_API_URL}${url}`) as Promise<GetPrivacyScoreResponseBody>
  }

  public getPublicPrivacyScore = (email: string): Promise<GetPrivacyScoreResponseBody> => {
    const url = `/public-dashboard/v1/privacy-score?email=${encodeURIComponent(email)}`
    return this.get(`${Constants.SPARTACUS_API_URL}${url}`) as Promise<GetPrivacyScoreResponseBody>
  }

  public getExposedDataSummary = (email?: string): Promise<GetExposedDataSummaryResponseBody> => {
    const url = email
      ? `/public-dashboard/v1/exposed-data?email=${encodeURIComponent(email)}`
      : '/dashboard/v1/exposed-data'

    return this.get(`${Constants.SPARTACUS_API_URL}${url}`) as Promise<
      GetExposedDataSummaryResponseBody
    >
  }

  public getSubscription = (): Promise<GetSubscriptionResponseBody> =>
    this.get(`${Constants.SPARTACUS_API_URL}/users/v1/subscription`) as Promise<
      GetSubscriptionResponseBody
    >

  public getScoreHistory = (): Promise<{
    data: GetScoreHistoryResponseBody
    status: number
  }> =>
    this.getWithStatus(
      `${Constants.SPARTACUS_API_URL}/dashboard/v1/privacy-score-history`,
    ) as Promise<{
      data: GetScoreHistoryResponseBody
      status: number
    }>

  public getKnownAssociates = (): Promise<{
    data: GetKnownAssociatesResponseBody
    status: number
  }> =>
    this.getWithStatus(`${Constants.SPARTACUS_API_URL}/dashboard/v1/associates`) as Promise<{
      data: GetKnownAssociatesResponseBody
      status: number
    }>

  public createUpdateSubscriptionSession = (): Promise<
    CreateUpdateSubscriptionSessionResponseBody
  > =>
    this.post<CreateUpdateSubscriptionSessionRequestBody>(
      `${Constants.SPARTACUS_API_URL}/users/v1/subscription`,
      {
        success_url: `${window.location.origin}/account`,
        cancel_url: `${window.location.origin}/account`,
      },
    ) as Promise<CreateUpdateSubscriptionSessionResponseBody>

  public cancelSubscription = (): Promise<{}> =>
    this.delete(`${Constants.SPARTACUS_API_URL}/users/v1/subscription`) as Promise<{}>

  public getUserRegistrationStatus = (
    email: string,
  ): Promise<GetUserRegistrationStatusResponseBody> =>
    this.get(
      `${Constants.SPARTACUS_API_URL}/users/v1/status?email=${encodeURIComponent(email)}`,
    ) as Promise<GetUserRegistrationStatusResponseBody>

  public validatePhoneNumber = (phoneNumber: string): Promise<ValidatePhoneNumberResponseBody> =>
    this.get(`${Constants.SPARTACUS_API_URL}/phone-numbers/${phoneNumber}/validate`) as Promise<
      ValidatePhoneNumberResponseBody
    >

  public requestPhoneNumberVerificationCode = (phoneNumber: string): Promise<{}> =>
    this.get(`${Constants.SPARTACUS_API_URL}/phone-numbers/${phoneNumber}/verify`)

  public verifyPhoneNumberVerificationCode = (
    phoneNumber: string,
    verificationCode: string,
  ): Promise<{}> =>
    this.get(
      `${Constants.SPARTACUS_API_URL}/phone-numbers/${phoneNumber}/verify/${verificationCode}`,
    )

  private buildHeaders = (): { [key: string]: string } => {
    const defaultHeaders: Record<string, string> = {
      'Content-Type': 'application/json',
    }

    if (this.authToken) {
      return {
        ...defaultHeaders,
        Authorization: `Bearer ${this.authToken}`,
      }
    }

    return defaultHeaders
  }

  private get = async (url: string): Promise<ResponseBody> => {
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          ...this.buildHeaders(),
        },
      })

      return response.data
    } catch (err) {
      return Promise.reject(this.getMessageFromError(err))
    }
  }

  private getWithStatus = async (url: string): Promise<{ data: ResponseBody; status: number }> => {
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          ...this.buildHeaders(),
        },
      })

      return { data: response.data, status: response.status }
    } catch (err) {
      return Promise.reject(this.getMessageFromError(err))
    }
  }

  private post = async <Body>(url: string, data: Body): Promise<ResponseBody> => {
    try {
      const response = await axios({
        method: 'post',
        url,
        headers: {
          ...this.buildHeaders(),
        },
        data,
      })

      return response.data
    } catch (err) {
      return Promise.reject(this.getMessageFromError(err))
    }
  }

  private patch = async <Body>(url: string, data: Body): Promise<ResponseBody> => {
    try {
      const response = await axios({
        method: 'patch',
        url,
        headers: {
          ...this.buildHeaders(),
        },
        data,
      })

      return response.data
    } catch (err) {
      return Promise.reject(this.getMessageFromError(err))
    }
  }

  private delete = async (url: string): Promise<ResponseBody> => {
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: {
          ...this.buildHeaders(),
        },
      })

      return response.data
    } catch (err) {
      return Promise.reject(this.getMessageFromError(err))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getMessageFromError = (err: any): string => {
    if (Object.prototype.hasOwnProperty.call(err, 'response')) {
      return err.response.data?.message || this.GENERIC_ERROR
    }

    return this.GENERIC_ERROR
  }
}
