import { action, runInAction, observable } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, {
  GetScoreHistoryResponseBody,
  ScoreHistoryResponseBody,
} from 'spartacus/services/TransportLayer'

export type RequestState = 'not started' | 'loading' | 'success' | 'failure'

export default class ScoreHistoryStore {
  private static RETRY_FREQUENCY = 2000

  @observable public requestState: RequestState = 'not started'
  @observable public scoreHistory = observable<ScoreHistoryResponseBody>([])

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @action public getScoreHistory = async (): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })

      const privacyScoreHistory = await this.pollForScoreHistory()

      runInAction((): void => {
        this.requestState = 'success'
        this.scoreHistory.replace(privacyScoreHistory)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  private pollForScoreHistory = async (): Promise<GetScoreHistoryResponseBody> => {
    try {
      const { data: privacyScoreHistory, status } = await this.transportLayer.getScoreHistory()

      if (status === 202) {
        await this.waitForRetry()
        return this.pollForScoreHistory()
      }

      return privacyScoreHistory as GetScoreHistoryResponseBody
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, ScoreHistoryStore.RETRY_FREQUENCY))
}
