import { action, runInAction, observable } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, {
  GetKnownAssociatesResponseBody,
  KnownAssociate,
} from 'spartacus/services/TransportLayer'

export type RequestState = 'not started' | 'loading' | 'success' | 'failure'

export default class KnownAssociatesStore {
  private static RETRY_FREQUENCY = 2000

  @observable public requestState: RequestState = 'not started'
  @observable public knownAssociates = observable<KnownAssociate>([])

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @action public retrieveKnownAssociates = async (): Promise<void> => {
    try {
      const knownAssociates = await this.pollForKnownAssociates()

      runInAction((): void => {
        this.knownAssociates.replace(knownAssociates)
      })
    } catch (e) {
      Sentry.captureException(`KnownAssociatesStore -> retrieveKnownAssociates -> ${e}`)
    }
  }

  @action public getKnownAssociates = async (): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })

      const knownAssociates = await this.pollForKnownAssociates()

      runInAction((): void => {
        this.requestState = 'success'
        this.knownAssociates.replace(knownAssociates)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  @action public registerAssociate = async (email: string, associateId: number): Promise<void> => {
    try {
      await this.transportLayer.registerAssociate(associateId, {
        email,
      })

      return Promise.resolve()
    } catch (e) {
      Sentry.captureException(`KnownAssociatesStore -> registerAssociate -> ${e}`)

      return Promise.reject(e)
    }
  }

  private pollForKnownAssociates = async (): Promise<GetKnownAssociatesResponseBody> => {
    try {
      const { data: knownAssociates, status } = await this.transportLayer.getKnownAssociates()

      if (status === 202) {
        await this.waitForRetry()
        return this.pollForKnownAssociates()
      }

      return knownAssociates as GetKnownAssociatesResponseBody
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, KnownAssociatesStore.RETRY_FREQUENCY))
}
