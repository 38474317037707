import GoogleTagManager from 'react-gtm-module'
import { observable, when, action, computed, reaction, runInAction } from 'mobx'
import ApolloClient from 'apollo-boost'
import { ReactSDKClient as OptimizelyClient } from '@optimizely/react-sdk'
import { SeverityQuery, Severity } from 'spartacus/types/__generated_graph'
import Constants from 'spartacus/constants'
import SessionStore from 'spartacus/stores/SessionStore'
import { Product, PricingPlan } from 'spartacus/stores/ProductStore'
import { SEVERITY_QUERY } from 'spartacus/hooks/useRiskScore'

declare global {
  interface Window {
    dataLayer: {}[]
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
}

export type DashboardViewType = 'premium' | 'enrolled' | 'free'

type GTMEvent = {
  event?: 'gtmEvent' | 'scrollTracking'
  category:
    | 'scroll tracking'
    | 'cta'
    | 'nav'
    | 'account'
    | 'footer'
    | 'email authorize'
    | 'email view public dashboard'
    | 'dashboard'
    | 'state'
    | 'enrollment'
    | 'user auth 2.0 enrollment'
    | 'log in'
  action: string
  label?: string
}

type PageView = {
  event: 'pageView'
  pageType: PageType
}

interface EECProduct {
  name: string
  id: string
  price: string
  category: 'subscription'
}

type EECProductImpression = {
  event: 'eec.productImpression'
  ecommerce: {
    currencyCode: 'USD'
    impressions: (EECProduct & {
      list: 'purchase funnel'
      position: number
    })[]
  }
}

type EECProductDetailView = {
  event: 'eec.productDetailView'
  ecommerce: {
    detail: {
      actionField: { list: 'purchase funnel' }
      products: EECProduct[]
    }
  }
}

type EECProductClick = {
  event: 'eec.productClick'
  ecommerce: {
    click: {
      actionField: { list: 'purchase funnel' }
      products: EECProduct[]
    }
  }
}

type EECAddToCart = {
  event: 'eec.addToCart'
  ecommerce: {
    currencyCode: 'USD'
    add: {
      products: (EECProduct & {
        quantity: number
      })[]
    }
  }
}

type EECRemoveFromCart = {
  event: 'eec.removeFromCart'
  ecommerce: {
    currencyCode: 'USD'
    remove: {
      products: (EECProduct & {
        quantity: number
      })[]
    }
  }
}

type EECCheckout = {
  event: 'eec.checkout'
  step: 1
  ecommerce: {
    checkout: {
      actionField: { step: 1 }
      products: (EECProduct & {
        quantity: number
      })[]
    }
  }
}

type EECPurchase = {
  event: 'eec.purchase'
  ecommerce: {
    purchase: {
      actionField: {
        id: string
        revenue: string
        tax: string
        coupon: string
      }
      products: (EECProduct & {
        quantity: number
      })[]
    }
  }
}

type Event =
  | GTMEvent
  | PageView
  | EECProductImpression
  | EECProductDetailView
  | EECProductClick
  | EECAddToCart
  | EECRemoveFromCart
  | EECCheckout
  | EECPurchase

enum PageType {
  Funnel = 'funnel',
  Dashboard = 'dashboard',
  Info = 'info',
  LogOut = 'LogOut',
  Unknown = 'unknown',
}

interface CustomDimensions {
  exposures?: string
  pagePath?: string
}

interface MatchedRoute extends CustomDimensions {
  pageType: PageType
}

type Route = (p: string) => boolean | MatchedRoute

/* eslint @typescript-eslint/explicit-function-return-type: 0 */
const routes: Route[] = [
  p =>
    p === '/' && {
      pageType: PageType.Funnel,
    },
  p =>
    p === '/offer' && {
      pageType: PageType.Funnel,
    },
  p =>
    p === '/purchase-confirmation' && {
      pageType: PageType.Funnel,
    },
  p =>
    p === '/dashboard' && {
      pageType: PageType.Dashboard,
    },
  p => {
    const match = /\/dashboard\/exposures\/(\d+)/.exec(p)

    if (!match) {
      return false
    }

    return {
      pageType: PageType.Dashboard,
      exposures: match[1],
      pagePath: '/dashboard/exposures',
    }
  },
  p =>
    p === '/dashboard/no-results' && {
      pageType: PageType.Dashboard,
    },
  p =>
    p === '/account' && {
      pageType: PageType.Dashboard,
    },

  p =>
    p === '/log-in' && {
      pageType: PageType.Funnel,
    },

  p =>
    p === '/authenticate' && {
      pageType: PageType.Funnel,
    },

  p =>
    p === '/logged-out' && {
      pageType: PageType.LogOut,
    },

  p =>
    p === '/privacy-policy' && {
      pageType: PageType.Info,
    },

  p =>
    p === '/terms-and-conditions' && {
      pageType: PageType.Info,
    },

  p =>
    p === '/our-promise' && {
      pageType: PageType.Info,
    },

  p =>
    p === '/faq' && {
      pageType: PageType.Info,
    },

  p =>
    p === '/jobs' && {
      pageType: PageType.Info,
    },

  p =>
    p === '/checkout' && {
      pageType: PageType.Funnel,
    },

  p =>
    p === '/update-payment' && {
      pageType: PageType.Funnel,
    },
]

const getProductNameFromProductAndPlan = (product: Product, plan: PricingPlan): string =>
  `${product.name} ${plan.name}`.trim()

export default class AnalyticsStore {
  @observable public isInitialized = false

  private sessionStore: SessionStore
  private apolloClient: ApolloClient<unknown>
  private optimizelyClient: OptimizelyClient
  private dataLayerQueue = observable<() => {}>([])

  public constructor(
    sessionStore: SessionStore,
    apolloClient: ApolloClient<unknown>,
    optimizelyClient: OptimizelyClient,
  ) {
    this.sessionStore = sessionStore
    this.apolloClient = apolloClient
    this.optimizelyClient = optimizelyClient

    reaction(() => this.dataLayerQueue.length, this.flushDataLayerQueue)
    when(() => this.sessionStore.fullyLoaded, this.initialize)
  }

  @action public pageview = (pagePathnameOverride?: string): void => {
    let pagePath = pagePathnameOverride || window.location.pathname

    if (pagePath.endsWith('/')) {
      pagePath = pagePath.slice(0, -1)
    }

    const { pageType, ...customDimensions } = this.getPageTypeAndCustomDimensionsByPathname(
      pagePath,
    )

    if (window.analytics) {
      window.analytics.page({
        path: pagePath,
      })
    }

    this.event({
      event: 'pageView',
      pagePath,
      pageType,
      ...customDimensions,
    })
  }

  @action public event = (data: Event): void => {
    const cachedSeverityResult = this.apolloClient.cache.readQuery<SeverityQuery>({
      query: SEVERITY_QUERY,
    })
    const riskCategory = cachedSeverityResult ? cachedSeverityResult.severity : Severity.Unknown

    this.dataLayerQueue.push(() => ({
      ...data,
      event: data.event || 'gtmEvent',
      userId: this.sessionStore.user?.id || 'unknown',
      userEmail: this.sessionStore.user?.email || this.sessionStore.publicEmail || 'unknown',
      loggedIn: Boolean(this.sessionStore.user),
      customerStatus: this.customerStatus,
      riskCategory,
    }))

    const eventsToNotTrackInSegment: Event['event'][] = ['pageView', 'scrollTracking']

    if (window.analytics && !eventsToNotTrackInSegment.includes(data.event)) {
      if (data.event === 'gtmEvent' || !data.event) {
        // Generic Events
        window.analytics.track(data.category, data)
      } else {
        window.analytics.track(data.event, data)
      }
    }
  }

  public track = (name: string, data?: {} | undefined): void => {
    if (window.analytics) {
      window.analytics.track(name, data)
    }
  }

  public getActionViewType = (): DashboardViewType => {
    if (this.sessionStore.abilities.can('read', 'Full-Protection Dashboard')) {
      return 'premium'
    }

    if (this.sessionStore.abilities.can('read', 'Freemium Dashboard')) {
      return 'enrolled'
    }

    return 'free'
  }

  public productImpression = (products: Product[]): void => {
    this.event({
      event: 'eec.productImpression',
      ecommerce: {
        currencyCode: 'USD',
        impressions: products.reduce((sum, product, index) => {
          return [
            ...sum,
            {
              name: getProductNameFromProductAndPlan(product, product.monthlyPlan),
              id: product.monthlyPlan.id,
              price: product.monthlyPlan.dollarAmountString,
              category: 'subscription',
              list: 'purchase funnel',
              position: index + 1,
            },
            {
              name: getProductNameFromProductAndPlan(product, product.yearlyPlan),
              id: product.yearlyPlan.id,
              price: product.yearlyPlan.dollarAmountString,
              category: 'subscription',
              list: 'purchase funnel',
              position: index + 1,
            },
          ]
        }, [] as EECProductImpression['ecommerce']['impressions']),
      },
    })
  }

  public productDetailView = (products: Product[]): void => {
    this.event({
      event: 'eec.productDetailView',
      ecommerce: {
        detail: {
          actionField: { list: 'purchase funnel' },
          products: products.reduce((sum, product) => {
            return [
              ...sum,
              {
                name: getProductNameFromProductAndPlan(product, product.monthlyPlan),
                id: product.monthlyPlan.id,
                price: product.monthlyPlan.dollarAmountString,
                category: 'subscription',
              },
              {
                name: getProductNameFromProductAndPlan(product, product.yearlyPlan),
                id: product.yearlyPlan.id,
                price: product.yearlyPlan.dollarAmountString,
                category: 'subscription',
              },
            ]
          }, [] as EECProduct[]),
        },
      },
    })
  }

  public productClick = (product: Product, plan: PricingPlan): void => {
    this.event({
      event: 'eec.productClick',
      ecommerce: {
        click: {
          actionField: { list: 'purchase funnel' },
          products: [
            {
              name: getProductNameFromProductAndPlan(product, plan),
              id: plan.id,
              price: plan.dollarAmountString,
              category: 'subscription',
            },
          ],
        },
      },
    })

    this.optimizelyClient.track('view_checkout')
  }

  public addToCart = (product: Product, plan: PricingPlan): void => {
    this.event({
      event: 'eec.addToCart',
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [
            {
              name: getProductNameFromProductAndPlan(product, plan),
              id: plan.id,
              price: plan.dollarAmountString,
              category: 'subscription',
              quantity: 1,
            },
          ],
        },
      },
    })
  }

  public checkout = (product: Product, plan: PricingPlan): void => {
    this.event({
      event: 'eec.checkout',
      step: 1,
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              name: getProductNameFromProductAndPlan(product, plan),
              id: plan.id,
              price: plan.dollarAmountString,
              category: 'subscription',
              quantity: 1,
            },
          ],
        },
      },
    })
  }

  public removeFromCart = (product: Product, plan: PricingPlan): void => {
    this.event({
      event: 'eec.removeFromCart',
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          products: [
            {
              name: getProductNameFromProductAndPlan(product, plan),
              id: plan.id,
              price: plan.dollarAmountString,
              category: 'subscription',
              quantity: 1,
            },
          ],
        },
      },
    })
  }

  public purchase = (product: Product, plan: PricingPlan, transactionID = ''): void => {
    this.event({
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionID,
            revenue: plan.dollarAmountString,
            tax: '0',
            coupon: '',
          },
          products: [
            {
              name: getProductNameFromProductAndPlan(product, plan),
              id: plan.id,
              price: plan.dollarAmountString,
              category: 'subscription',
              quantity: 1,
            },
          ],
        },
      },
    })

    this.optimizelyClient.track('purchase')
  }

  @computed private get customerStatus():
    | 'subscribed'
    | 'suspended'
    | 'unsubscribed'
    | 'email_only'
    | 'guest' {
    if (this.sessionStore.user?.hasBillingIssues) {
      return 'suspended'
    }

    if (this.sessionStore.user?.isUnsubscribed) {
      return 'unsubscribed'
    }

    if (this.sessionStore.user?.hasActiveSubscription) {
      return 'subscribed'
    }

    if (this.sessionStore.publicEmail) {
      return 'email_only'
    }

    return 'guest'
  }

  private initialize = (): void => {
    if (typeof window !== 'undefined') {
      GoogleTagManager.initialize({
        gtmId: Constants.GOOGLE_TAG_MANAGER_ID,
      })

      if (window.analytics) {
        window.analytics.load(Constants.SEGMENT_WRITE_KEY)
      }

      runInAction((): void => {
        this.isInitialized = true
      })

      this.flushDataLayerQueue()
    }
  }

  private flushDataLayerQueue = (): void => {
    if (this.sessionStore.fullyLoaded && typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).dataLayer.push(
        ...this.dataLayerQueue.map(func => {
          const event = func()

          return event
        }),
      )
      runInAction((): void => {
        this.dataLayerQueue.replace([])
      })
    }
  }

  private getPageTypeAndCustomDimensionsByPathname = (pathname: string): MatchedRoute => {
    let match

    for (const route of routes) {
      match = route(pathname)

      if (match) {
        break
      }
    }

    if (match) {
      return match as MatchedRoute
    }

    return {
      pageType: PageType.Unknown,
    }
  }
}
