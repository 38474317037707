import * as Sentry from '@sentry/browser'
import { action, runInAction, observable } from 'mobx'
import { StripeCardElement } from '@stripe/stripe-js'
import Stripe from 'spartacus/services/Stripe'
import SessionStore from 'spartacus/stores/SessionStore'
import AnalyticsStore from 'spartacus/stores/AnalyticsStore'
import { Product, PricingPlan } from 'spartacus/stores/ProductStore'
import TransportLayer, {
  CreateCouponResponseBody,
  CreateSubscriptionRequestBody,
} from 'spartacus/services/TransportLayer'

export default class CartStore {
  @observable public isInitialized = false
  @observable public product?: Product
  @observable public plan?: PricingPlan
  @observable public loading = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable public error?: any

  private transportLayer: TransportLayer
  private sessionStore: SessionStore
  private analyticsStore: AnalyticsStore

  public constructor(
    transportLayer: TransportLayer,
    sessionStore: SessionStore,
    analyticsStore: AnalyticsStore,
  ) {
    this.transportLayer = transportLayer
    this.sessionStore = sessionStore
    this.analyticsStore = analyticsStore

    this.initialize()
  }

  @action public addToCart = (product: Product, plan: PricingPlan): void => {
    if (this.product && this.plan) {
      this.analyticsStore.removeFromCart(this.product, this.plan)
    }

    this.product = product
    this.plan = plan
    this.analyticsStore.addToCart(product, plan)
  }

  @action public emptyCart = (): void => {
    if (this.product && this.plan) {
      this.analyticsStore.removeFromCart(this.product, this.plan)
      this.product = undefined
      this.plan = undefined
    }
  }

  @action public purchase = async ({
    cardElement,
    email,
    name,
    promotionCode,
  }: {
    cardElement: StripeCardElement
    email: string
    name: string
    promotionCode?: string
  }): Promise<void> => {
    try {
      runInAction((): void => {
        this.error = undefined
        this.loading = true
      })

      if (!Stripe.instance || !this.product || !this.plan) {
        throw new Error()
      }

      const { paymentMethod, error } = await Stripe.instance.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email,
          name,
        },
      })

      if (error) {
        throw error
      }

      if (!paymentMethod) {
        throw new Error()
      }

      const subscription: CreateSubscriptionRequestBody = {
        payment_method: paymentMethod?.id,
        plan: this.plan.id,
      }

      if (this.sessionStore.abilities.cannot('read', 'Freemium Dashboard')) {
        // If the user hasn't created a password yet, pass this mode to the subscription
        subscription.mode = 'signup' // cspell: disable-line
      }

      if (promotionCode) {
        subscription.promotion_code = promotionCode
      }

      const { subscription: transactionID } = await this.transportLayer.createSubscription(
        subscription,
      )

      this.sessionStore.signUpSubscriptionID = transactionID

      this.analyticsStore.purchase(this.product, this.plan, transactionID)

      if (!this.sessionStore.user) {
        // Set the entered email as the publicEmail so that we can populate the request code input
        this.sessionStore.publicEmail = email
      }

      runInAction((): void => {
        this.loading = false
      })

      return Promise.resolve()
    } catch (e) {
      runInAction((): void => {
        this.error = e
        this.loading = false
      })
      Sentry.captureException(e)

      return Promise.reject()
    }
  }

  @action public createCoupon = async ({
    promotionCode,
    email,
  }: {
    promotionCode: string
    email?: string
  }): Promise<void | CreateCouponResponseBody> => {
    try {
      runInAction((): void => {
        this.error = undefined
      })

      if (!Stripe.instance || !this.product || !this.plan) {
        throw new Error()
      }

      const result = await this.transportLayer.createCoupon({
        email,
        promotion_code: promotionCode,
        plan: this.plan.id,
      })

      return result
    } catch (e) {
      Sentry.captureException(e)

      return Promise.reject(e)
    }
  }

  public redirectToManageSubscription = async (): Promise<void> => {
    try {
      if (!Stripe.instance) {
        throw new Error('Stripe not initialized')
      }

      const { id: sessionID } = await this.transportLayer.createUpdateSubscriptionSession()
      this.analyticsStore.pageview('/update-payment')
      await Stripe.instance.redirectToCheckout({
        sessionId: sessionID,
      })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  public cancelSubscription = async (): Promise<void> => {
    try {
      await this.transportLayer.cancelSubscription()
      this.sessionStore.logOut()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  private initialize = async (): Promise<null> => {
    try {
      await Stripe.instance

      runInAction((): void => {
        this.isInitialized = true
      })
    } catch (e) {
      Sentry.captureException(e)
    }

    return null
  }
}
