import Cookie from 'js-cookie'
import { v4 as uuidV4 } from 'uuid'

export const getDeviceID = (): string => {
  let id = Cookie.get('spartacusID')

  if (!id) {
    id = uuidV4()
    Cookie.set('spartacusID', id, { expires: 7 })
  }

  return id
}

export default {
  getDeviceID,
}
