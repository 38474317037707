import { action, runInAction, observable, ObservableMap } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer, { GetPrivacyScoreResponseBody } from 'spartacus/services/TransportLayer'

export type RequestState = 'not started' | 'loading' | 'success' | 'failure'
export type PrivacyRequestType = ObservableMap<
  keyof {
    privacy_score: number
  }
>

export default class PrivacyScoreStore {
  private static RETRY_FREQUENCY = 2000

  private static initialPrivacyScore = {
    privacyScore: 0,
  }

  @observable public requestState: RequestState = 'not started'
  @observable public privacyScore: PrivacyRequestType = observable.map({
    ...PrivacyScoreStore.initialPrivacyScore,
  })

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @action public getPrivacyScore = async (email: string | undefined): Promise<void> => {
    try {
      runInAction((): void => {
        this.requestState = 'loading'
      })

      const privacyScore = await this.pollForPrivacyScore(email)

      runInAction((): void => {
        this.requestState = 'success'
        this.privacyScore.replace(privacyScore)
      })
    } catch (e) {
      Sentry.captureException(e)
      runInAction((): void => {
        this.requestState = 'failure'
      })
    }
  }

  private pollForPrivacyScore = async (
    email: string | undefined,
  ): Promise<GetPrivacyScoreResponseBody> => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const privacyScore: any = email
        ? await this.transportLayer.getPublicPrivacyScore(email)
        : await this.transportLayer.getPrivacyScore()

      runInAction((): void => {
        this.privacyScore.replace(privacyScore)
      })

      if (privacyScore.status === 'queued') {
        await this.waitForRetry()
        return this.pollForPrivacyScore(email)
      }

      return privacyScore as GetPrivacyScoreResponseBody
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private waitForRetry = async (): Promise<number> =>
    new Promise(resolve => setTimeout(resolve, PrivacyScoreStore.RETRY_FREQUENCY))
}
