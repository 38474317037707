import { Datacenter, datadogLogs, StatusType } from '@datadog/browser-logs'
import { Context, ContextValue } from '@datadog/browser-core'
import Constants from 'spartacus/constants'

class Logger {
  private initialized = false

  public constructor() {
    if (Constants.ENVIRONMENT !== 'local' && Constants.IS_BROWSER) {
      datadogLogs.init({
        clientToken: Constants.DATADOG_TOKEN,
        datacenter: Datacenter.US,
        forwardErrorsToLogs: false,
        sampleRate: 100,
        service: 'spartacus-com',
        env: Constants.ENVIRONMENT,
        version: Constants.RELEASE_VERSION,
      })

      this.initialized = true
    }
  }

  public debug(message: string, context?: Context): void {
    this.log(message, context, StatusType.debug)
  }

  public info(message: string, context?: Context): void {
    this.log(message, context, StatusType.info)
  }

  public warn(message: string, context?: Context): void {
    this.log(message, context, StatusType.warn)
  }

  public error(message: string, context?: Context): void {
    this.log(message, context, StatusType.error)
  }

  public addContext(key: string, value: ContextValue): void {
    if (this.initialized) {
      datadogLogs.logger.addContext(key, value)
    }
  }

  private log(message: string, context?: Context, level?: StatusType): void {
    if (this.initialized) {
      datadogLogs.logger.log(message, context, level)

      return
    }

    console.log(`[${level}] ${message}`, context || '') // eslint-disable-line no-console
  }
}

export const logger = new Logger()
