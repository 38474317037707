import { Stripe, loadStripe } from '@stripe/stripe-js'
import Constants from 'spartacus/constants'

class StripeService {
  public instance: Stripe | null = null
  public instancePromise: Promise<Stripe | null>
  private initialized = false

  public constructor() {
    this.instancePromise = loadStripe(Constants.STRIPE_API_KEY).then(stripe => {
      this.initialized = true
      this.instance = stripe

      return stripe
    })
  }
}

export default new StripeService()
