export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type BillingProfile = {
  __typename?: 'BillingProfile'
  /** Email associated with Stripe */
  email?: Maybe<Scalars['String']>
  /** User's current subscription */
  subscription?: Maybe<BillingSubscription>
}

export enum BillingStatus {
  Active = 'active',
  Canceled = 'canceled',
  Free = 'free',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export type BillingSubscription = {
  __typename?: 'BillingSubscription'
  id: Scalars['String']
  plan?: Maybe<BillingSubscriptionPlan>
  status: BillingStatus
}

export type BillingSubscriptionPlan = {
  __typename?: 'BillingSubscriptionPlan'
  amount?: Maybe<Scalars['Int']>
  id: Scalars['String']
  interval: BillingSubscriptionPlanInterval
  type: BillingSubscriptionPlanType
}

export enum BillingSubscriptionPlanInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export enum BillingSubscriptionPlanType {
  Full = 'full',
  Limited = 'limited',
}

/** A CSID Subscriber Profile */
export type CreditProfile = {
  __typename?: 'CreditProfile'
  /** Indicates whether the subscriber is active. Valid values are Y or N. */
  active: Scalars['Boolean']
  /** Address line 1 of the subscriber. */
  address1: Scalars['String']
  /** Address line 2 of the subscriber. */
  address2: Scalars['String']
  /** The subscriber city or town. */
  city: Scalars['String']
  /** Unique subscriber's ID */
  creditID: Scalars['ID']
  /** Email address of the subscriber. Must be in proper email format, including the @ symbol and a period. */
  email: Scalars['String']
  /** Subscriber's first name */
  firstName: Scalars['String']
  /** Subscriber's last name */
  lastName: Scalars['String']
  /** Middle initial of the subscriber */
  middleInitial: Scalars['String']
  /** Telephone number of the subscriber without any dash characters. */
  phone: Scalars['String']
  /**
   * The two-character abbreviation of the subscriber state of residence.
   *
   * Example: `"TX"`
   */
  state: Scalars['String']
  /** The generational suffix, such as SR, JR, I, II, III, IV, V. Special characters are not allowed. */
  suffix: Scalars['String']
  /** Subscriber 5-digit zip code. The additional 4-digit extension is not supported. */
  zip: Scalars['String']
}

export type CreditReport = {
  __typename?: 'CreditReport'
  /** Contains all account details associated with the customer. */
  accounts: Array<Maybe<CreditReportAccount>>
  /** Contains all addresses associated with the customer. */
  addresses: Array<Maybe<CreditReportAddress>>
  /** The customer's year of birth, in YYYY format. */
  birthYear?: Maybe<Scalars['Int']>
  /**
   * The name of the reporting bureau.
   *
   * Example: `"Experian"`
   */
  bureau?: Maybe<Scalars['String']>
  /** Contains all statements associated with the customer. */
  consumerStatements: Array<Maybe<CreditReportConsumerStatement>>
  /** The date and time the credit report was ordered, in ISO 8601 format. */
  date?: Maybe<Scalars['String']>
  /** Contains all employers associated with the customer. */
  employers: Array<Maybe<CreditReportEmployer>>
  /** Contains all inquiries associated with the customer. */
  inquiries: Array<Maybe<CreditReportInquiry>>
  /**
   * Contains all names associated with the customer.
   *
   * The first consumer name listed is the best name.
   */
  names: Array<Maybe<CreditReportName>>
  /** Contains all public records associated with the customer. */
  publicRecords: Array<Maybe<CreditReportPublicRecord>>
  /** Contains details related to the customer credit score. */
  scoreDetails: Array<Maybe<CreditReportScoreDetail>>
}

export type CreditReportAccount = {
  __typename?: 'CreditReportAccount'
  /**
   * Current balance of the account.
   *
   * Example: `4400`
   */
  balance?: Maybe<Scalars['Int']>
  /**
   * Date the balance on the account was updated, in ISO 8601 format.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  balanceDate?: Maybe<Scalars['String']>
  /**
   * The industry (kind of business) of the trade item.
   *
   * Examples: `"Auto Rental"` `"Variety Stores"`
   */
  businessType?: Maybe<Scalars['String']>
  /**
   * Account type classification.
   *
   * Examples: `"Revolving account"` `"REVOLVING"`
   */
  classification?: Maybe<Scalars['String']>
  /** Any additional comments or details */
  comments: Array<Maybe<CreditReportAccountComment>>
  /** Details about the creditor associated with the account. */
  creditor?: Maybe<CreditReportCreditor>
  /** Number of times a payment was made on the account 30 or more days late. */
  delinquent30DaysCount?: Maybe<Scalars['Int']>
  /** Number of times a payment was made on the account 30 or more days late. */
  delinquent60DaysCount?: Maybe<Scalars['Int']>
  /** Number of times a payment was made on the account 90 or more days late. */
  delinquent90DaysCount?: Maybe<Scalars['Int']>
  /** Highest historical balance on the account. */
  highBalance?: Maybe<Scalars['Int']>
  /** Maximum limit of the account. */
  limit?: Maybe<Scalars['Int']>
  /**
   * Required monthly payment on the account.
   *
   * Example: `48`
   */
  monthlyPayment?: Maybe<Scalars['Int']>
  /**
   * Creditor name.
   *
   * Example: `"ABCD Bank"`
   */
  name?: Maybe<Scalars['String']>
  /**
   * Account number. The last four digits are masked.
   *
   * Example: `"1022211122XXXX"`
   */
  number?: Maybe<Scalars['String']>
  /** The date the account was opened, in ISO 8601 format. */
  openDate?: Maybe<Scalars['String']>
  /**
   * Indicates if the account is currently open or closed.
   *
   * Examples: `"Open"` `"Closed"`
   */
  openStatus?: Maybe<Scalars['String']>
  /** Original dollar amount of the loan. */
  originalAmount?: Maybe<Scalars['Int']>
  /** The creditor name if the account classification is COLLECTION. */
  originalCreditorName?: Maybe<Scalars['String']>
  /** If the account is delinquent, the payment amount owed. */
  pastDueAmount?: Maybe<Scalars['Int']>
  /**
   * Includes details, by month, about the payments made on the accounts.
   *
   * Begins with the most recent month and calendar year.
   */
  paymentHistories: Array<Maybe<CreditReportAccountPaymentHistory>>
  /**
   * The payment status category.
   *
   * Examples: `"Pays account as agreed"` `"Unknown"`
   */
  paymentStatusClass?: Maybe<Scalars['String']>
  /** Any additional remarks or details associated with the account. */
  remarks: Array<Maybe<CreditReportAccountRemark>>
  /**
   * Indicates the payment responsibility of the customer on the account.
   *
   * Examples: `"Individual"` `"Joint Account"`
   */
  responsibility?: Maybe<Scalars['String']>
  /** The date of the account status change. */
  statusDate?: Maybe<Scalars['String']>
  /**
   * Features or terms of the account.
   *
   * If the loan term is reported, the time period of the loan in months.
   *
   * Examples: `"Revolving"` `"Unknown"` `"360 Months"` `"60 Months"` `"1 Month"`
   */
  terms?: Maybe<Scalars['String']>
  /**
   * Description of the account.
   *
   * Examples: `"Revolving"` `"Secured Loan"`
   */
  type?: Maybe<Scalars['String']>
  /** Additional data about the account. */
  typeDefinitionFlags: CreditReportTypeDefinitionFlags
}

export type CreditReportAccountComment = {
  __typename?: 'CreditReportAccountComment'
  /**
   * Comment text.
   *
   * Example: `"Account closed at consumer’s request."`
   */
  text?: Maybe<Scalars['String']>
}

export type CreditReportAccountPaymentHistory = {
  __typename?: 'CreditReportAccountPaymentHistory'
  /** Payment status for April. */
  april?: Maybe<Scalars['String']>
  /** Payment status for August. */
  august?: Maybe<Scalars['String']>
  /** Payment status for December. */
  december?: Maybe<Scalars['String']>
  /** Payment status for February. */
  february?: Maybe<Scalars['String']>
  /** Payment status for January. */
  january?: Maybe<Scalars['String']>
  /** Payment status for July. */
  july?: Maybe<Scalars['String']>
  /** Payment status for June. */
  june?: Maybe<Scalars['String']>
  /** Payment status for March. */
  march?: Maybe<Scalars['String']>
  /** Payment status for May. */
  may?: Maybe<Scalars['String']>
  /** Payment status for November. */
  november?: Maybe<Scalars['String']>
  /** Payment status for October. */
  october?: Maybe<Scalars['String']>
  /** Payment status for September. */
  september?: Maybe<Scalars['String']>
  /** The calendar year */
  year?: Maybe<Scalars['Int']>
}

export type CreditReportAccountRemark = {
  __typename?: 'CreditReportAccountRemark'
  /** Additional remarks about the account. */
  text?: Maybe<Scalars['String']>
}

export type CreditReportAddress = {
  __typename?: 'CreditReportAddress'
  /**
   * The city associated with the customer address.
   *
   * Example: `"Oxford"`
   */
  city?: Maybe<Scalars['String']>
  /**
   * The date the customer address was reported, in ISO 8601 format.
   *
   * Depending on the score model, this field may not be returned.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  firstReported?: Maybe<Scalars['String']>
  /**
   * The customer's house number.
   *
   * Examples: `"16"` `"1322 S"`
   */
  houseNumber?: Maybe<Scalars['String']>
  /**
   * The date the customer address was updated, in ISO 8601 format.
   *
   * Depending on the score model, this field may not be returned.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  lastUpdated?: Maybe<Scalars['String']>
  /**
   * A directional that follows a street address.
   *
   * This field is returned in TransUnion reports but is not included in Experian or Equifax reports.
   *
   * Examples: `"N"` `"E"` `"NW"`
   */
  postdirectional?: Maybe<Scalars['String']>
  /**
   * The directional prefix associated with the street address.
   *
   * This field is returned in TransUnion reports but is not included in Experian or Equifax reports.
   *
   * Examples: `"N"` `"E"` `"NW"`
   */
  predirectional?: Maybe<Scalars['String']>
  /**
   * The state or two-character state abbreviation associated with the customer address.
   *
   * Example: `"TX"`
   */
  state?: Maybe<Scalars['String']>
  /**
   * The customer's street name.
   *
   * Examples: `"Main St"` `"Elm"`
   */
  streetName?: Maybe<Scalars['String']>
  /**
   * The customer's street address suffix.
   *
   * Examples: `"St"` `"Avenue"`
   */
  suffix?: Maybe<Scalars['String']>
  /**
   * The type of residence associated with the customer address.
   *
   * Examples: `"Apartment complex"` `"Firm"` `"General Delivery"` `"Multi-family dwelling"` `"Post Office Box"` `"Rural route"` `"Single-family dwelling"` `"Unknown"` `"Military"` `"Mexican address"` `"Canadian address"`
   */
  type?: Maybe<Scalars['String']>
  /**
   * The dwelling unit number.
   *
   * Examples: `"B"` `"8"` `"#4"`
   */
  unit?: Maybe<Scalars['String']>
  /**
   * The zip code associated with the customer address.
   *
   * This value may include the +4 zip code digits.
   *
   * Examples: `"78888"` `"78998-0859"`
   */
  zip?: Maybe<Scalars['String']>
}

export type CreditReportConsumerStatement = {
  __typename?: 'CreditReportConsumerStatement'
  /**
   * The credit bureau associated with the consumer statement.
   *
   * Example: `"EX"`
   */
  bureau?: Maybe<Scalars['String']>
  /**
   * The consumer statement timestamp, in ISO 8601 format.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  date?: Maybe<Scalars['String']>
  /**
   * General supplemental statements from the consumer.
   *
   * Example: `"ID FRAUD VICTIM ALERT: FRAUDULENT APPLICATIONS MAY BE SUBMITTED IN MY NAME OR MY IDENTITY MAY HAVE BEEN USED WITHOUT MY CONSENT TO FRAUDULENTLY OBTAIN GOODS OR SERVICES."`
   */
  statement?: Maybe<Scalars['String']>
}

export type CreditReportCreditor = {
  __typename?: 'CreditReportCreditor'
  /** Address of creditor */
  address?: Maybe<Scalars['String']>
  /** City of creditor */
  city?: Maybe<Scalars['String']>
  /**
   * Name of creditor
   *
   * Example: `"ABC Credit Company"`
   */
  name?: Maybe<Scalars['String']>
  /** Phone number of creditor */
  phone?: Maybe<Scalars['String']>
  /** State of creditor */
  state?: Maybe<Scalars['String']>
  /** Zip code of creditor */
  zip?: Maybe<Scalars['String']>
}

export type CreditReportEmployer = {
  __typename?: 'CreditReportEmployer'
  /**
   * Date the employer record was reported, in ISO 8601 format.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  firstReported?: Maybe<Scalars['String']>
  /**
   * Date the employer record was last updated, in ISO 8601 format.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  lastUpdated?: Maybe<Scalars['String']>
  /**
   * Employer name.
   *
   * Examples: `"ABC INC"` `"Retired"`
   */
  name?: Maybe<Scalars['String']>
}

export type CreditReportInquiry = {
  __typename?: 'CreditReportInquiry'
  /**
   * Name of inquiring company
   *
   * Example: `"ABC Credit Company"`
   */
  companyName?: Maybe<Scalars['String']>
  /**
   * Type of inquiring company
   *
   * Example: `"Mortgage Reporters"`
   */
  companyType?: Maybe<Scalars['String']>
  creditor: CreditReportCreditor
  /**
   * Date of inquiry
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  date?: Maybe<Scalars['String']>
}

export type CreditReportName = {
  __typename?: 'CreditReportName'
  /** Consumer first name. */
  firstName?: Maybe<Scalars['String']>
  /** Consumer last name. */
  lastName?: Maybe<Scalars['String']>
  /** Consumer middle initial or name. */
  middleName?: Maybe<Scalars['String']>
}

export type CreditReportPublicRecord = {
  __typename?: 'CreditReportPublicRecord'
  /** Amount associated with the public record. */
  amount?: Maybe<Scalars['Int']>
  /** Asset amount. */
  assetAmount?: Maybe<Scalars['String']>
  /** Contains comment text associated with the public record. This data may be provided by the consumer or by the reporter. */
  comments: Array<Maybe<CreditReportPublicRecordComment>>
  /** The name of the court associated with the public record. */
  courtName?: Maybe<Scalars['String']>
  /**
   * Type of court associated with the record.
   *
   * Examples:  `"State"`  `"Federal"`
   */
  courtType?: Maybe<Scalars['String']>
  /** Date the record was filed, in ISO 8601 format. */
  filedDate?: Maybe<Scalars['String']>
  /** Date the record was updated, in ISO 8601 format. */
  lastUpdated?: Maybe<Scalars['String']>
  /**
   * Liability amount.
   *
   * Examples:  `"000003765"` `"UNKNOWN"`
   */
  liabilityAmount?: Maybe<Scalars['String']>
  /** The case number associated with the record. */
  referenceNumber?: Maybe<Scalars['String']>
  /**
   * The party responsible for payment on the account.
   *
   * Examples:  `"Individual"`  `"Business"`  `"Joint Account"`
   */
  responsibility?: Maybe<Scalars['String']>
  /**
   * Description of the record.
   *
   * Examples:  `"Small claims judgment"`  `"Bankruptcy: Individual"`  `"Bankruptcy chapter 7-petition filed"`
   */
  type?: Maybe<Scalars['String']>
}

export type CreditReportPublicRecordComment = {
  __typename?: 'CreditReportPublicRecordComment'
  /** Additional comments in the record. */
  text?: Maybe<Scalars['String']>
}

export type CreditReportScoreDetail = {
  __typename?: 'CreditReportScoreDetail'
  /** The credit bureau */
  bureau?: Maybe<Scalars['String']>
  /**
   * Indicates the time the score was pulled, in ISO 8601 format.
   *
   * Example: `"2018-09- 07T00:00:00.000+00:00"`
   */
  date?: Maybe<Scalars['String']>
  /** Factors that contribute to the score */
  factors: Array<Maybe<CreditScoreFactor>>
  /** The credit score rating. */
  rating?: Maybe<Scalars['String']>
  /**
   * The credit score.
   *
   * Example: `633`
   */
  score?: Maybe<Scalars['Int']>
}

export type CreditReportTypeDefinitionFlags = {
  __typename?: 'CreditReportTypeDefinitionFlags'
  /**
   * For tradeline accounts, indicates if the account includes derogatory data.
   *
   * For non-tradeline accounts, the value for this field is 0.
   *
   * Examples: `"AllClear"` `"Negative"` `"0"` `"other"`
   */
  derogatoryData?: Maybe<Scalars['String']>
  /** Indicates whether the account is in collection. */
  isInCollection?: Maybe<Scalars['Boolean']>
  /** Indicates if the account is a trade line account. */
  isTradeline?: Maybe<Scalars['Boolean']>
  /**
   * For tradeline accounts, indicates whether the account is opened or closed.
   *
   * For non-tradeline accounts, the value for this field is 0.
   *
   * Examples: `"Closed"` `"Open"` `"Indeterminate"` `"0"`
   */
  status?: Maybe<Scalars['String']>
  /**
   * For tradeline accounts, indicates the account type.
   *
   * For non-tradeline accounts, the value for this field is 0.
   *
   * Examples: `"Revolving"` `"RealEstate"` `"Installment"` `"0"` `"other"`
   */
  type?: Maybe<Scalars['String']>
}

export type CreditScore = {
  __typename?: 'CreditScore'
  /** Indicates the time the score was pulled, in ISO 8601 format. */
  date: Scalars['String']
  /** Factors that contribute to the score */
  factors: Array<Maybe<CreditScoreFactor>>
  /**
   * The credit score rating.
   *
   * Examples: `"Very Poor"` `"Poor"` `"Fair"` `"Good"` `"Very Good"`
   */
  rating: Scalars['String']
  /** The credit score. */
  score: Scalars['String']
}

export type CreditScoreFactor = {
  __typename?: 'CreditScoreFactor'
  /** Internal Code. Positive factors start with "P" */
  code: Scalars['String']
  /** The detailed description of the factor */
  description: Scalars['String']
  /** If the factor contributed to a score increase */
  isPositive: Scalars['Boolean']
  /** The short description of the factor */
  shortDescription: Scalars['String']
  /** A tip to help the user increase or maintain their score. */
  tip: Scalars['String']
}

export enum CreditStatus {
  Active = 'active',
  Inactive = 'inactive',
  Locked = 'locked',
  Registered = 'registered',
}

export type CreditVerificationQuestionSet = {
  __typename?: 'CreditVerificationQuestionSet'
  /** Question Set ID */
  id: Scalars['ID']
  /** The order of the question */
  number: Scalars['String']
  /** Array of possible answers to the question */
  possibleAnswers: Array<CreditVerificationQuestionSetPossibleAnswer>
  /** The text of the question */
  text: Scalars['String']
}

export type CreditVerificationQuestionSetPossibleAnswer = {
  __typename?: 'CreditVerificationQuestionSetPossibleAnswer'
  /** Possible answer ID */
  id: Scalars['ID']
  /** The order of the answer */
  number: Scalars['String']
  /** The text of the answer */
  text: Scalars['String']
}

export type CreditVerificationQuestionSetsResponse = {
  __typename?: 'CreditVerificationQuestionSetsResponse'
  /** Question Set ID */
  id: Scalars['ID']
  /** Array of Question Sets */
  questionSets: Array<CreditVerificationQuestionSet>
}

export type EnrollInCreditServiceInput = {
  /** Last four digits of user's Social Security Number */
  lastFourOfSSN: Scalars['String']
}

export type EnrollInCreditServiceResponse = {
  __typename?: 'EnrollInCreditServiceResponse'
  /** User's credit service ID */
  creditID: Scalars['ID']
  /** User's verification status in the credit service. */
  creditStatus: CreditStatus
}

/** Mutations */
export type Mutation = {
  __typename?: 'Mutation'
  TEST__gatherUsersForNotifications?: Maybe<Scalars['Boolean']>
  /** Test send emails */
  TEST__sendEmails?: Maybe<Scalars['Boolean']>
  creditServiceEnroll: EnrollInCreditServiceResponse
  /** @deprecated use 'creditServiceEnroll' */
  enrollInCreditService: EnrollInCreditServiceResponse
  sendInsuranceCertificate?: Maybe<Scalars['Boolean']>
  verifyCreditQuestionSets: VerifyCreditQuestionSetsResponse
}

/** Mutations */
export type MutationCreditServiceEnrollArgs = {
  ssn: Scalars['String']
}

/** Mutations */
export type MutationEnrollInCreditServiceArgs = {
  input: EnrollInCreditServiceInput
}

/** Mutations */
export type MutationVerifyCreditQuestionSetsArgs = {
  input: VerifyCreditQuestionSetsInput
}

/** Root Query */
export type Query = {
  __typename?: 'Query'
  /** Get the user's billing profile as stored in Stripe */
  billingProfile?: Maybe<BillingProfile>
  /** Get Subscriber's profile */
  creditProfile: CreditProfile
  /** Gets the user's credit report */
  creditReport: CreditReport
  /** Gets the user's credit scores */
  creditScores: Array<Maybe<CreditScore>>
  /** Get the user's credit status */
  creditStatus?: Maybe<CreditStatus>
  /** Gets a set of questions and answers for the user to verify */
  creditVerificationQuestionSets: CreditVerificationQuestionSetsResponse
  healthCheck: Scalars['Boolean']
  privacySeverity: PrivacySeverity
  /** Gets a user's risk score */
  riskScore?: Maybe<Scalars['Int']>
  severity: Severity
  /** Get authenticated User */
  user?: Maybe<User>
}

/** Root Query */
export type QueryCreditScoresArgs = {
  endDate?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
}

/** Root Query */
export type QueryRiskScoreArgs = {
  email?: Maybe<Scalars['String']>
}

export enum Role {
  Administrator = 'administrator',
  Subscriber = 'subscriber',
}

export type User = {
  __typename?: 'User'
  /** User's street address. At most 180 characters and can only contain numbers, letters, and the following: .-# */
  address1?: Maybe<Scalars['String']>
  /** User's unit/apartment/suite etc. At most 180 characters and can only contain numbers and letters */
  address2?: Maybe<Scalars['String']>
  /** User's Billing Status */
  billingStatus: BillingStatus
  /** User's city. At most 30 characters and can only contain letters and spaces */
  city?: Maybe<Scalars['String']>
  /** User's Credit Status */
  creditStatus?: Maybe<CreditStatus>
  /**
   * User's birth date in the following format: yyyy-MM-dd
   *
   * Examples: `"1985-04-16"`
   */
  dateOfBirth?: Maybe<Scalars['String']>
  /** User's email address */
  email?: Maybe<Scalars['String']>
  /** User's first name. At most 30 characters and can only contain letters, numbers, and spaces */
  firstName?: Maybe<Scalars['String']>
  /** User's full name */
  fullName?: Maybe<Scalars['String']>
  /** Auth0 ID */
  id: Scalars['String']
  /** User's last name. At most 30 characters and can only contain letters, spaces, hyphens, and apostrophes */
  lastName?: Maybe<Scalars['String']>
  /** User's Role */
  roles: Array<Maybe<Role>>
  /** User's state. Two capital letters */
  state?: Maybe<Scalars['String']>
  /** User's Stripe ID */
  stripeID?: Maybe<Scalars['String']>
  /** User's zip code. Five numbers */
  zip?: Maybe<Scalars['String']>
}

export type VerifyCreditQuestionSetAnswerInput = {
  /** The order of the question */
  questionNumber: Scalars['String']
  /** The order of the answer */
  answerNumber: Scalars['String']
  /** Question Set ID */
  questionID: Scalars['ID']
  /** Answer ID */
  answerID: Scalars['ID']
}

export type VerifyCreditQuestionSetsInput = {
  /** Question Set ID */
  id?: Maybe<Scalars['ID']>
  /** Array of Answers */
  answers: Array<VerifyCreditQuestionSetAnswerInput>
}

export type VerifyCreditQuestionSetsResponse = {
  __typename?: 'VerifyCreditQuestionSetsResponse'
  /** Additional array of Question Sets. Present only if verified is false */
  questionSets?: Maybe<Array<Maybe<CreditVerificationQuestionSet>>>
  /** Whether the user is verified or not */
  verified: Scalars['Boolean']
}

export enum Severity {
  Unknown = 'Unknown',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum PrivacySeverity {
  Unknown = 'Unknown',
  NeedsWork = 'NeedsWork',
  Decent = 'Decent',
  Great = 'Great',
}

export type CreditReportQueryVariables = Exact<{ [key: string]: never }>

export type CreditReportQuery = { __typename?: 'Query' } & {
  creditReport: { __typename?: 'CreditReport' } & Pick<
    CreditReport,
    'bureau' | 'date' | 'birthYear'
  > & {
      scoreDetails: Array<
        Maybe<
          { __typename?: 'CreditReportScoreDetail' } & Pick<
            CreditReportScoreDetail,
            'rating' | 'score'
          > & {
              factors: Array<
                Maybe<
                  { __typename?: 'CreditScoreFactor' } & Pick<
                    CreditScoreFactor,
                    'shortDescription' | 'description'
                  >
                >
              >
            }
        >
      >
      names: Array<
        Maybe<
          { __typename?: 'CreditReportName' } & Pick<
            CreditReportName,
            'firstName' | 'middleName' | 'lastName'
          >
        >
      >
      addresses: Array<
        Maybe<
          { __typename?: 'CreditReportAddress' } & Pick<
            CreditReportAddress,
            | 'houseNumber'
            | 'predirectional'
            | 'streetName'
            | 'suffix'
            | 'postdirectional'
            | 'unit'
            | 'city'
            | 'state'
            | 'zip'
            | 'firstReported'
            | 'lastUpdated'
          >
        >
      >
      employers: Array<
        Maybe<
          { __typename?: 'CreditReportEmployer' } & Pick<
            CreditReportEmployer,
            'name' | 'firstReported' | 'lastUpdated'
          >
        >
      >
      consumerStatements: Array<
        Maybe<
          { __typename?: 'CreditReportConsumerStatement' } & Pick<
            CreditReportConsumerStatement,
            'bureau' | 'statement' | 'date'
          >
        >
      >
      inquiries: Array<
        Maybe<
          { __typename?: 'CreditReportInquiry' } & Pick<
            CreditReportInquiry,
            'companyName' | 'companyType' | 'date'
          > & {
              creditor: { __typename?: 'CreditReportCreditor' } & Pick<
                CreditReportCreditor,
                'name' | 'address' | 'city' | 'state' | 'zip' | 'phone'
              >
            }
        >
      >
      accounts: Array<
        Maybe<
          { __typename?: 'CreditReportAccount' } & Pick<
            CreditReportAccount,
            | 'name'
            | 'number'
            | 'type'
            | 'balance'
            | 'balanceDate'
            | 'businessType'
            | 'classification'
            | 'openDate'
            | 'statusDate'
            | 'delinquent30DaysCount'
            | 'delinquent60DaysCount'
            | 'delinquent90DaysCount'
            | 'originalAmount'
            | 'highBalance'
            | 'limit'
            | 'openStatus'
            | 'monthlyPayment'
            | 'originalCreditorName'
            | 'pastDueAmount'
            | 'responsibility'
            | 'terms'
          > & {
              comments: Array<
                Maybe<
                  { __typename?: 'CreditReportAccountComment' } & Pick<
                    CreditReportAccountComment,
                    'text'
                  >
                >
              >
              creditor?: Maybe<
                { __typename?: 'CreditReportCreditor' } & Pick<
                  CreditReportCreditor,
                  'name' | 'phone' | 'address' | 'city' | 'state' | 'zip'
                >
              >
              paymentHistories: Array<
                Maybe<
                  { __typename?: 'CreditReportAccountPaymentHistory' } & Pick<
                    CreditReportAccountPaymentHistory,
                    | 'year'
                    | 'january'
                    | 'february'
                    | 'march'
                    | 'april'
                    | 'may'
                    | 'june'
                    | 'july'
                    | 'august'
                    | 'september'
                    | 'october'
                    | 'november'
                    | 'december'
                  >
                >
              >
              remarks: Array<
                Maybe<
                  { __typename?: 'CreditReportAccountRemark' } & Pick<
                    CreditReportAccountRemark,
                    'text'
                  >
                >
              >
            }
        >
      >
      publicRecords: Array<
        Maybe<
          { __typename?: 'CreditReportPublicRecord' } & Pick<
            CreditReportPublicRecord,
            | 'amount'
            | 'type'
            | 'referenceNumber'
            | 'responsibility'
            | 'courtName'
            | 'courtType'
            | 'filedDate'
            | 'lastUpdated'
            | 'liabilityAmount'
            | 'assetAmount'
          > & {
              comments: Array<
                Maybe<
                  { __typename?: 'CreditReportPublicRecordComment' } & Pick<
                    CreditReportPublicRecordComment,
                    'text'
                  >
                >
              >
            }
        >
      >
    }
}

export type CreditMonitoringQueryVariables = Exact<{ [key: string]: never }>

export type CreditMonitoringQuery = { __typename?: 'Query' } & {
  creditScores: Array<
    Maybe<
      { __typename?: 'CreditScore' } & Pick<CreditScore, 'date' | 'rating' | 'score'> & {
          factors: Array<
            Maybe<
              { __typename?: 'CreditScoreFactor' } & Pick<
                CreditScoreFactor,
                'description' | 'shortDescription'
              >
            >
          >
        }
    >
  >
}

export type RiskScoreQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>
}>

export type RiskScoreQuery = { __typename?: 'Query' } & Pick<Query, 'riskScore'>

export type SeverityQueryVariables = Exact<{ [key: string]: never }>

export type SeverityQuery = { __typename?: 'Query' } & Pick<Query, 'severity'>

export type BillingProfileQueryVariables = Exact<{ [key: string]: never }>

export type BillingProfileQuery = { __typename?: 'Query' } & {
  billingProfile?: Maybe<
    { __typename?: 'BillingProfile' } & {
      subscription?: Maybe<
        { __typename?: 'BillingSubscription' } & {
          plan?: Maybe<
            { __typename?: 'BillingSubscriptionPlan' } & Pick<BillingSubscriptionPlan, 'type'>
          >
        }
      >
    }
  >
}

export type CreditStatusQueryVariables = Exact<{ [key: string]: never }>

export type CreditStatusQuery = { __typename?: 'Query' } & Pick<Query, 'creditStatus'>

export type CreditServiceEnrollMutationVariables = Exact<{
  ssn: Scalars['String']
}>

export type CreditServiceEnrollMutation = { __typename?: 'Mutation' } & {
  creditServiceEnroll: { __typename?: 'EnrollInCreditServiceResponse' } & Pick<
    EnrollInCreditServiceResponse,
    'creditID' | 'creditStatus'
  >
}

export type VerifyCreditQuestionSetsMutationVariables = Exact<{
  input: VerifyCreditQuestionSetsInput
}>

export type VerifyCreditQuestionSetsMutation = { __typename?: 'Mutation' } & {
  verifyCreditQuestionSets: { __typename?: 'VerifyCreditQuestionSetsResponse' } & Pick<
    VerifyCreditQuestionSetsResponse,
    'verified'
  >
}

export type CreditVerificationQuestionSetsQueryVariables = Exact<{ [key: string]: never }>

export type CreditVerificationQuestionSetsQuery = { __typename?: 'Query' } & {
  creditVerificationQuestionSets: { __typename?: 'CreditVerificationQuestionSetsResponse' } & Pick<
    CreditVerificationQuestionSetsResponse,
    'id'
  > & {
      questionSets: Array<
        { __typename?: 'CreditVerificationQuestionSet' } & Pick<
          CreditVerificationQuestionSet,
          'id' | 'number' | 'text'
        > & {
            possibleAnswers: Array<
              { __typename?: 'CreditVerificationQuestionSetPossibleAnswer' } & Pick<
                CreditVerificationQuestionSetPossibleAnswer,
                'id' | 'number' | 'text'
              >
            >
          }
      >
    }
}
