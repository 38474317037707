import { action, observable, runInAction } from 'mobx'
import * as Sentry from '@sentry/browser'
import TransportLayer from 'spartacus/services/TransportLayer'

export default class AccountSettingsStore {
  @observable public loading = true
  @observable public fullyLoaded = false
  @observable public planName?: string
  @observable public transactionID?: string
  @observable public renewsOnString?: string
  @observable public billingType?: 'card' | 'ideal' | 'sepa_debit'
  @observable public billingPostalCode?: string
  @observable public priceString?: string
  @observable public cardBrand?: string
  @observable public cardLast4?: string

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @action public fetchSubscription = async (): Promise<void> => {
    runInAction((): void => {
      this.loading = true
      this.fullyLoaded = false
    })

    try {
      const {
        subscription,
        billing_info: billingInfo,
      } = await this.transportLayer.getSubscription()

      runInAction((): void => {
        this.loading = false
        this.fullyLoaded = true
        this.planName = subscription.plan_name
        this.priceString = `$${subscription.amount / 100}`
        this.transactionID = billingInfo.transaction_id
        // TODO: Need to handle cancelled subscriptions
        this.renewsOnString = this.formatDate(subscription.current_period_end)
        this.billingType = billingInfo.type
        this.billingPostalCode = billingInfo.billing_details.postal_code
        this.cardBrand = billingInfo.card && billingInfo.card.brand.toUpperCase()
        this.cardLast4 = billingInfo.card && billingInfo.card.last4
      })
    } catch (e) {
      Sentry.captureException(e)

      runInAction((): void => {
        this.loading = false
      })
    }
  }

  @action public createBillingPortalSession = async (): Promise<void> => {
    runInAction((): void => {
      this.loading = true
    })

    try {
      const { customer_portal_url } = await this.transportLayer.createBillingPortalSession()

      runInAction((): void => {
        this.loading = false
      })

      window.location.assign(customer_portal_url)
    } catch (e) {
      Sentry.captureException(e)

      runInAction((): void => {
        this.loading = false
      })
    }
  }

  private formatDate = (secondsSinceEpoch: number): string => {
    const date = new Date(secondsSinceEpoch * 1000)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()

    return `${month}/${day}/${year}`
  }
}
